<template>
    <Modal v-model="showForm" :title="modalTitle" width="860" :mask-closable="false">
        <Form class="plain-form" ref="editForm" :model="editForm" :rules="editFormRule" label-colon :label-width="90">
            <div class="custom-form">
                <Alert type="warning" show-icon v-for="(error, index) in errorArray" :key="index">{{error}}</Alert>

                <div class="divider">职位信息</div>
                <div class="custom-form-section">
                    <Row :gutter="16">
                        <Col span="12">
                            <FormItem label="客户">{{ myPosition.customerName }}</FormItem>
                        </Col>
                        <Col span="12">
                            <FormItem label="职位">{{ myPosition.name }}</FormItem>
                        </Col>
                    </Row>
                    <Row :gutter="16">
                        <Col span="12">
                            <FormItem label="类型">{{ myPosition.typeName }}</FormItem>
                        </Col>
                        <Col span="12">
                            <FormItem label="级别">{{ myPosition.levelName }}</FormItem>
                        </Col>
                    </Row>
                    <Row :gutter="16">
                        <Col span="12">
                            <FormItem label="金额">{{ amount }}</FormItem>
                        </Col>
                        <Col span="12">
                            <FormItem label="顾问">{{ myPosition.consultantNames }}</FormItem>
                        </Col>
                    </Row>
                    <Row :gutter="16">
                        <Col span="12">
                            <FormItem label="联系人">{{ myPosition.contact }}</FormItem>
                        </Col>
                        <Col span="12">
                            <FormItem label="联系电话">{{ myPosition.contactPhone }}</FormItem>
                        </Col>
                    </Row>
                    <Row :gutter="16">
                        <Col span="12">
                            <FormItem label="联系人职务">{{ myPosition.contactTitle }}</FormItem>
                        </Col>
                        <Col span="12">
                            <FormItem label="创建人">{{ myPosition.createByName }}</FormItem>
                        </Col>
                    </Row>
                    <Row :gutter="16">
                        <Col span="24">
                            <FormItem label="创建时间">{{ myPosition.createTime }}</FormItem>
                        </Col>
                    </Row>
                </div>

                <div class="divider">交付进度<span class="tips">注：录入业绩提审后，之前步骤不可编辑</span></div>
                <div class="custom-form-section">
                    <Tabs type="card" v-model="currentStep" size="small" :animated="false">
                        <TabPane label="1. 已交付人数" name="handover">
                            <div class="tabs-form">
                                <FormItem label="数量" required>
                                    <Input v-model="editForm.handoverQuantity" placeholder="请输入" :disabled="isCostReadOnly"></Input>
                                </FormItem>
                                <FormItem label="备注">
                                    <Input type="textarea" v-model="editForm.handoverMemo" placeholder="请输入" :maxlength="500" :autosize="{minRows: 2,maxRows: 5}" show-word-limit :disabled="isCostReadOnly" />
                                </FormItem>
                            </div>
                        </TabPane>
                        <TabPane label="2. 首付款" name="payment">
                            <div class="tabs-form">
                                <FormItem label="金额" required>
                                    <Input v-model="editForm.payment" placeholder="请输入" :disabled="isCostReadOnly"></Input>
                                </FormItem>
                                <FormItem label="备注">
                                    <Input type="textarea" v-model="editForm.paymentMemo" placeholder="请输入" :maxlength="500" :autosize="{minRows: 2,maxRows: 5}" show-word-limit :disabled="isCostReadOnly" />
                                </FormItem>
                                <FormItem label="附件" required>
                                    <Upload multiple type="drag"
                                            :headers="header"
                                            :max-size="10 * 1024"
                                            :action="uploadFiles"
                                            :data="{type: 'private'}"
                                            :show-upload-list="false"
                                            :format="['jpg', 'png', 'JPG', 'JPEG']"
                                            accept=".jpg,.png,.JPG,.JPEG"
                                            :on-exceeded-size="handExceeded"
                                            :on-success="(res, file, fileList) => handleSuccess(res, file, fileList, 'paymentFileArray')"
                                            v-if="!isCostReadOnly">
                                        <div class="upload-btn-l">
                                            <Icon type="md-cloud-upload"></Icon>
                                            <p class="tips">注意：支持.jpg, .jpeg, .png格式, 10M以内</p>
                                        </div>
                                    </Upload>
                                    <div class="upload-image-list" v-for="(item, index) in paymentFileArray">
                                        <img :src="item.imgData" style=""/>
                                        <div class="upload-image-list-cover">
                                            <Icon type="ios-eye" @click.native="handlePreview(item.imgData)"></Icon>
                                            <Icon type="ios-trash" @click.native="handleRemove('paymentFileArray', index)" v-if="!isCostReadOnly"></Icon>
                                        </div>
                                    </div>
                                </FormItem>
                            </div>
                        </TabPane>
                        <TabPane label="3. 录入成本" name="cost">
                            <div class="tabs-form">
                                <FormItem label="金额" required>
                                    <Input v-model="editForm.cost" placeholder="请输入" :disabled="isCostReadOnly"></Input>
                                </FormItem>
                                <FormItem label="备注">
                                    <Input type="textarea" v-model="editForm.costMemo" placeholder="请输入" :maxlength="500" :autosize="{minRows: 2,maxRows: 5}" show-word-limit :disabled="isCostReadOnly" />
                                </FormItem>
                                <FormItem label="附件" required>
                                    <Upload multiple type="drag"
                                            :headers="header"
                                            :max-size="10 * 1024"
                                            :action="uploadFiles"
                                            :data="{type: 'private'}"
                                            :show-upload-list="false"
                                            :format="['jpg', 'png', 'JPG', 'JPEG']"
                                            accept=".jpg,.png,.JPG,.JPEG"
                                            :on-exceeded-size="handExceeded"
                                            :on-success="(res, file, fileList) => handleSuccess(res, file, fileList, 'costFileArray')"
                                            v-if="!isCostReadOnly">
                                        <div class="upload-btn-l">
                                            <Icon type="md-cloud-upload"></Icon>
                                            <p class="tips">注意：支持.jpg, .jpeg, .png格式, 10M以内</p>
                                        </div>
                                    </Upload>
                                    <div class="upload-image-list" v-for="(item, index) in costFileArray">
                                        <img :src="item.imgData" style=""/>
                                        <div class="upload-image-list-cover">
                                            <Icon type="ios-eye" @click.native="handlePreview(item.imgData)"></Icon>
                                            <Icon type="ios-trash" @click.native="handleRemove('costFileArray', index)" v-if="!isCostReadOnly"></Icon>
                                        </div>
                                    </div>
                                </FormItem>
                                <FormItem class="plain-item" label="审批进度">
                                    <Timeline>
                                        <TimelineItem v-for="(item, index) in costApprovalHistoryArray" :value="index" :key="index">
                                            <div class="status">{{ item.status }} {{ item.comment }}</div>
                                            <div class="operator">{{ item.operatorName }} {{ item.updatedTime }}</div>
                                        </TimelineItem>
                                    </Timeline>
                                </FormItem>
                            </div>
                        </TabPane>
                        <TabPane label="4. 录入业绩" name="performance">
                            <div class="tabs-form">
                                <FormItem label="业绩金额" required>
                                    <Input v-model="editForm.performance" placeholder="请输入" :disabled="!showApprovalBtn"></Input>
                                </FormItem>
                                <FormItem label="完成时间" required>
                                    <DatePicker type="date" placeholder="请选择" v-model="editForm.completionTime" :editable="false" :disabled="!showApprovalBtn"></DatePicker>
                                </FormItem>
                                <FormItem label="备注">
                                    <Input type="textarea" v-model="editForm.performanceMemo" placeholder="请输入" :maxlength="500" :autosize="{minRows: 2,maxRows: 5}" show-word-limit :disabled="!showApprovalBtn" />
                                </FormItem>
                                <FormItem class="plain-item" label="审批进度">
                                    <Timeline>
                                        <TimelineItem v-for="(item, index) in approvalHistoryArray" :value="index" :key="index">
                                            <div class="status">{{ item.status }} {{ item.comment }}</div>
                                            <div class="operator">{{ item.operatorName }} {{ item.updatedTime }}</div>
                                        </TimelineItem>
                                    </Timeline>
                                </FormItem>
                            </div>
                        </TabPane>
                        <TabPane label="5. 尾款" name="balance">
                            <div class="tabs-form">
                                <FormItem label="金额" required>
                                    <Input v-model="editForm.balance" placeholder="请输入" :disabled="!showApprovalBalBtn"></Input>
                                </FormItem>
                                <FormItem label="备注">
                                    <Input type="textarea" v-model="editForm.balanceMemo" placeholder="请输入" :maxlength="500" :autosize="{minRows: 2,maxRows: 5}" show-word-limit :disabled="!showApprovalBalBtn" />
                                </FormItem>
                                <FormItem label="附件" required>
                                    <Upload multiple type="drag"
                                            :headers="header"
                                            :max-size="10 * 1024"
                                            :action="uploadFiles"
                                            :data="{type: 'private'}"
                                            :show-upload-list="false"
                                            :format="['jpg', 'png', 'JPG', 'JPEG']"
                                            accept=".jpg,.png,.JPG,.JPEG"
                                            :on-exceeded-size="handExceeded"
                                            :on-success="(res, file, fileList) => handleSuccess(res, file, fileList, 'balanceFileArray')"
                                            v-if="showApprovalBalBtn">
                                        <div class="upload-btn-l">
                                            <Icon type="md-cloud-upload"></Icon>
                                            <p class="tips">注意：支持.jpg, .jpeg, .png格式, 10M以内</p>
                                        </div>
                                    </Upload>
                                    <div class="upload-image-list" v-for="(item, index) in balanceFileArray">
                                        <img :src="item.imgData" style=""/>
                                        <div class="upload-image-list-cover">
                                            <Icon type="ios-eye" @click.native="handlePreview(item.imgData)"></Icon>
                                            <Icon type="ios-trash" @click.native="handleRemove('balanceFileArray', index)" v-if="showApprovalBalBtn"></Icon>
                                        </div>
                                    </div>
                                </FormItem>
                            </div>
                        </TabPane>
                        <TabPane label="6. 录入业绩" name="balPerformance">
                            <div class="tabs-form">
                                <FormItem label="业绩金额" required>
                                    <Input v-model="editForm.balPerformance" placeholder="请输入" :disabled="!showApprovalBalBtn"></Input>
                                </FormItem>
                                <FormItem label="完成时间" required>
                                    <DatePicker type="date" placeholder="请选择" v-model="editForm.balPerformanceTime" :editable="false" :disabled="!showApprovalBalBtn"></DatePicker>
                                </FormItem>
                                <FormItem label="备注">
                                    <Input type="textarea" v-model="editForm.balPerformanceMemo" placeholder="请输入"  :maxlength="500" :autosize="{minRows: 2,maxRows: 5}" show-word-limit :disabled="!showApprovalBalBtn" />
                                </FormItem>
                                <FormItem class="plain-item" label="审批进度">
                                    <Timeline>
                                        <TimelineItem v-for="(item, index) in balApprovalHistoryArray" :value="index" :key="index">
                                            <div class="status">{{ item.status }}<span v-if="item.comment !== null">{{ item.comment }}</span></div>
                                            <div class="operator">{{ item.operatorName }} {{ item.updatedTime }}</div>
                                        </TimelineItem>
                                    </Timeline>
                                </FormItem>
                            </div>
                        </TabPane>
                    </Tabs>
                </div>
            </div>
        </Form>
        <Modal title="查看图片" v-model="showPreview" footer-hide :width="640">
            <img :src="imgView" v-if="showPreview" style="width: 100%"/>
        </Modal>
        <div slot="footer">
            <Button type="default" @click="closeModal(false)">取消</Button>
            <Button type="primary" :loading="loading" @click="handleSubmit('save')" v-if="!isCostReadOnly && (currentStep === 'handover' || currentStep === 'payment')">提交</Button>
            <Button type="primary" :loading="loading" @click="handleSubmit('approvalCost')" v-if="showApprovalCostBtn && currentStep === 'cost'">提审</Button>
            <Button type="primary" :loading="loading" @click="handleSubmit('approval')" v-if="showApprovalBtn && currentStep === 'performance'">提审</Button>
            <Button type="primary" :loading="loading" @click="handleSubmit('saveBal')" v-if="showApprovalBalBtn && currentStep === 'balance'">提交</Button>
            <Button type="primary" :loading="loading" @click="handleSubmit('approvalBal')" v-if="showApprovalBalBtn && currentStep === 'balPerformance'">提审</Button>
        </div>
    </Modal>
</template>
<script>
import {
    reqGetOpenDelivery,
    reqSubmitDelivery,
    reqGetApprovalHistoryByDeliveryId
} from "../../../api/admin-api";
import {downloadFile, reqDeleteFiles, uploadFileURL} from "../../../api/system-api";

export default {
    props: {
        value: {
            type: Boolean,
            default: false
        },
        position: {}
    },
    data() {
        return {
            amount: null,
            showForm: this.value,
            modalTitle: null,
            loading: false,
            myPosition: {typeName: ''},
            currentStep: 'handover',
            header: null,
            uploadFiles: uploadFileURL,
            paymentFileArray: [],
            costFileArray: [],
            balanceFileArray: [],
            showPreview: false,
            imgView: null,
            isCostReadOnly: false,
            isPayReadOnly: false,
            isBalReadOnly: false,
            showApprovalCostBtn: false,
            showApprovalBtn: false,
            showApprovalBalBtn: false,
            errorArray: [],
            editForm: {
                id: null,
                positionId: null,
                handoverQuantity: null,
                handoverMemo: null,
                payment: null,
                paymentMemo: null,
                cost: null,
                costMemo: null,
                performance: null,
                performanceMemo: null,
                performanceTime: null,
                completionTime: null,
                balance: null,
                balanceMemo: null,
                balPerformance: null,
                balPerformanceMemo: null,
                balPerformanceTime: null,
            },
            editFormRule:{},
            costApprovalHistoryArray: [],
            approvalHistoryArray: [],
            balApprovalHistoryArray: [],
        }
    },
    methods: {
        init() {
            let token = sessionStorage.getItem('Authorization');
            this.header = {Authorization: token};

            let promiseSummary = new Promise((resolve, reject) => {
                reqGetOpenDelivery({id: this.myPosition.id}).then((res) => {
                    this.editForm = res.data.data || {};

                    this.editForm.paymentFile && JSON.parse(this.editForm.paymentFile).forEach(name => {
                        downloadFile({fileName: name, type: 'private'}).then((res2) => {
                            let myData = window.URL.createObjectURL(new Blob([res2.data]));
                            this.paymentFileArray.push({imgName: name, imgData: myData});
                        });
                    });

                    this.editForm.costFile && JSON.parse(this.editForm.costFile).forEach(name => {
                        downloadFile({fileName: name, type: 'private'}).then((res2) => {
                            let myData = window.URL.createObjectURL(new Blob([res2.data]));
                            this.costFileArray.push({imgName: name, imgData: myData});
                        });
                    });

                    this.editForm.balanceFile && JSON.parse(this.editForm.balanceFile).forEach(name => {
                        downloadFile({fileName: name, type: 'private'}).then((res2) => {
                            let myData = window.URL.createObjectURL(new Blob([res2.data]));
                            this.balanceFileArray.push({imgName: name, imgData: myData});
                        });
                    });

                    resolve(true);
                }).catch((err) => {
                    console.log('err======', err)
                    this.editForm = {};
                })
            });

            Promise.all([promiseSummary]).then(value => {
                reqGetApprovalHistoryByDeliveryId({deliveryId: this.editForm.id}).then((res) => {
                    this.costApprovalHistoryArray = res.data.data?.filter(item => item.type === '录入成本');
                    this.approvalHistoryArray = res.data.data?.filter(item => item.type === '录入业绩');
                    this.balApprovalHistoryArray = res.data.data?.filter(item => item.type === '尾款业绩');
                }).catch((err) => {
                    this.costApprovalHistoryArray = [];
                    this.approvalHistoryArray = [];
                    this.balApprovalHistoryArray = [];
                })

                // 成本信息只读？
                this.isCostReadOnly = (this.editForm.approvalStatus > -3);
                this.showApprovalCostBtn = (this.editForm.approvalStatus === -4 || this.editForm.approvalStatus === -3);
                // 首付款信息只读？
                this.isPayReadOnly = (this.editForm.approvalStatus > 1);
                this.showApprovalBtn = (this.editForm.approvalStatus === 0 || this.editForm.approvalStatus === 1)
                // 尾付款款信息只读？
                this.isBalReadOnly = !(this.editForm.approvalStatus > 5);
                this.showApprovalBalBtn = (this.editForm.approvalStatus === 4 || this.editForm.approvalStatus === 5);

                this.currentStep = 'handover';
                if (this.editForm.approvalStatus === -3 || this.editForm.approvalStatus === -2 || this.editForm.approvalStatus === -1) {
                    this.currentStep = 'cost';
                }
                if (this.editForm.approvalStatus === 0 || this.editForm.approvalStatus === 1 || this.editForm.approvalStatus === 2 || this.editForm.approvalStatus === 3) {
                    this.currentStep = 'performance';
                }
                if (this.editForm.approvalStatus === 4) {
                    this.currentStep = 'balance';
                }
                if (this.editForm.approvalStatus === 5 || this.editForm.approvalStatus === 6 || this.editForm.approvalStatus === 7) {
                    this.currentStep = 'balPerformance';
                }
            }).catch(reason => {
                console.log(reason);
            })
        },
        //上传文件大小
        handExceeded(file) {
            this.$Message.error('文件大小不得超过10M');
        },
        //上传成功
        handleSuccess(res, file, fileList, propName) {
            if (res.code === 1) {
                let name = res.data.currentName;
                downloadFile({fileName: name, type: 'private'}).then((res2) => {
                    let myData = window.URL.createObjectURL(new Blob([res2.data]));
                    this[propName].push({imgName: name, imgData: myData});
                    this.$Message.success('上传成功');
                });
            } else {
                this.$Message.error('上传失败');
            }
        },
        handlePreview(imgData) {
            this.imgView = imgData;
            this.showPreview = true;
        },
        handleRemove(propName, index) {
            reqDeleteFiles([{fileName: this[propName][index].imgName, type: 'private'}]).then((res) => {
                if (res.data.data && res.data.data.length > 0 && res.data.data[0].result) {
                    this[propName].splice(index, 1);
                } else {
                    this.$Message.error('删除失败');
                }
            })
        },
        handleSubmit(type) {
            this.loading = true;
            if (this.validateForm(type)) {
                this.loading = false;
                return false;
            }

            this.editForm.positionId = this.myPosition.id;
            this.editForm.paymentFile = this.paymentFileArray.map(item => item.imgName);
            this.editForm.costFile = this.costFileArray.map(item => item.imgName);
            this.editForm.balanceFile = this.balanceFileArray.map(item => item.imgName);
            this.editForm.submitType = type;
            this.editForm.positionTypeName = this.myPosition.typeName;

            reqSubmitDelivery(this.editForm).then((res) => {
                this.loading = false;
                if (res.data.data === 1) {
                    this.$Message.success('提交成功');
                    this.$emit('getPosition');
                    this.closeModal(false);
                } else {
                    this.$Message.error('提交失败');
                }
            }).catch(()=>{
                this.loading = false;
                this.$Message.error('提交失败');
            })
        },
        validateForm(type) {
            this.errorArray = [];
            if (type === 'approvalCost') {
                if (!this.editForm.handoverQuantity) {
                    this.errorArray.push('请输入交付数量');
                }
                if (!this.editForm.payment) {
                    this.errorArray.push('请输入首付款金额');
                }
                if (this.editForm.payment === '0') {
                    this.errorArray.push('首付款金额不能为0');
                }
                if (!this.paymentFileArray || this.paymentFileArray.length === 0) {
                    this.errorArray.push('请上传首付款附件');
                }
                if (!this.editForm.cost) {
                    this.errorArray.push('请输入成本金额');
                }
                if (this.editForm.payment === '0') {
                    this.errorArray.push('成本金额不能为0');
                }
                if (!this.costFileArray || this.costFileArray.length === 0) {
                    this.errorArray.push('请上传成本附件');
                }
            }
            if (type === 'approval') {
                if (!this.editForm.performance) {
                    this.errorArray.push('请输入业绩金额');
                }
                if (this.editForm.performance === '0') {
                    this.errorArray.push('业绩金额不能为0');
                }
                if (!this.editForm.completionTime) {
                    this.errorArray.push('请选择完成时间');
                }
            }
            if (type === 'approvalBal') {
                if (!this.editForm.balance) {
                    this.errorArray.push('请输入尾款金额');
                }
                if (this.editForm.balance === '0') {
                    this.errorArray.push('尾款金额不能为0');
                }
                if (!this.balanceFileArray || this.balanceFileArray.length === 0) {
                    this.errorArray.push('请上传尾款附件');
                }
                if (!this.editForm.balPerformance) {
                    this.errorArray.push('请输入尾款业绩金额');
                }
                if (this.editForm.balPerformance === '0') {
                    this.errorArray.push('尾款业绩金额不能为0');
                }
                if (!this.editForm.balPerformanceTime) {
                    this.errorArray.push('请选择尾款业绩完成时间');
                }
            }

            let quantity = /^[1-9]{1}\d{0,7}$/
            if (this.editForm.handoverQuantity && !quantity.test(this.editForm.handoverQuantity)) {
                this.errorArray.push('请输入正确的交付数量。交付数量为整数，且不大于99999999');
            }

            let regex = /^(([1-9]{1}\d{0,8})|(0{1}))(\.\d{1,2})?$/;
            if (this.editForm.payment && !regex.test(this.editForm.payment)) {
                this.errorArray.push('请输入正确的首付款金额。最多两位小数，最大金额99999999.99');
            }
            if (this.editForm.cost && !regex.test(this.editForm.cost)) {
                this.errorArray.push('请输入正确的成本金额。最多两位小数，最大金额99999999.99');
            }
            if (this.editForm.performance && !regex.test(this.editForm.performance)) {
                this.errorArray.push('请输入正确的业绩金额。最多两位小数，最大金额99999999.99');
            }
            if (this.editForm.balance && !regex.test(this.editForm.balance)) {
                this.errorArray.push('请输入正确的尾款金额。最多两位小数，最大金额99999999.99');
            }
            if (this.editForm.balPerformance && !regex.test(this.editForm.balPerformance)) {
                this.errorArray.push('请输入正确的尾款业绩金额。最多两位小数，最大金额99999999.99');
            }

            return (this.errorArray.length > 0);
        },
        closeModal(val) {
            this.$emit('input', val);
        }
    },
    watch: {
        value(val) {
            this.showForm = val;
        },
        showForm(val) {
            if (val) {
                this.myPosition = this.position;
                this.amount = this.myPosition.amount.toFixed(2);
                this.modalTitle = '职位交付 (ID:' + this.position.custId + ')';
                this.init();
            } else {// 反之则关闭页面
                this.paymentFileArray = [];
                this.costFileArray = [];
                this.balanceFileArray = [];
                this.showPreview = false;
                this.imgView = null;
                this.isCostReadOnly = false;
                this.isPayReadOnly = false;
                this.isBalReadOnly = false;
                this.errorArray = [];
                this.approvalHistoryArray = [];
                this.balApprovalHistoryArray = [];
                this.closeModal(val);
            }
        }
    }
}
</script>
