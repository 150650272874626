<template>
    <Modal v-model="showForm" title="新增简历" width="860" :mask-closable="false">
        <div class="filter">
            <Form ref="filter" label-colon inline>
                <FormItem class="plain-item-margin full-width">
                    <Input v-model="filter.keywords" placeholder="请输入ID，姓名，职位，手机号码，工作经历" clearable/>
                </FormItem>
                <FormItem class="plain-item-margin">
                    <Select v-model="filter.gender" placeholder="请选择性别" clearable>
                        <Option value="男">男</Option>
                        <Option value="女">女</Option>
                    </Select>
                </FormItem>
                <FormItem class="plain-item-margin">
                    <Select v-model="filter.degree" placeholder="请选择学历" clearable>
                        <Option v-for="item in degreeArray" :value="item.id" :key="item.id">{{ item.name }}</Option>
                    </Select>
                </FormItem>
                <FormItem>
                    <treeselect v-model="filter.intention"
                                :max-height="130"
                                :options="cityOpts"
                                :disable-branch-nodes="true"
                                :clear-on-select="clearOnSelect"
                                placeholder="请选择意向城市"
                                loadingText="加载中..."
                                noOptionsText="暂无数据"
                                noResultsText="没有匹配数据">
                    </treeselect>
                </FormItem>
                <div class="group-select">
                    <FormItem>
                        <Select v-model="filter.salaryFrom" @on-change="setSalaryToMax" placeholder="请选择最低年薪" filterable clearable>
                            <Option v-for="item in salaryFromArray" :value="item.id" :key="item.id">{{ item.name }}</Option>
                        </Select>
                    </FormItem>
                    至
                    <FormItem>
                        <Select v-model="filter.salaryTo" placeholder="请选择最高年薪" filterable clearable>
                            <Option v-for="item in salaryToArray" :value="item.id" :key="item.id">{{ item.name }}</Option>
                        </Select>
                    </FormItem>
                </div>
                <FormItem>
                    <Button type="primary" @click="doSearching">搜索</Button>
                    <Button type="primary" @click="handleReset">重置</Button>
                </FormItem>
            </Form>
        </div>
        <div class="table">
            <div class="table-list">
                <Table stripe :columns="columns" :data="dataList">
                    <template slot-scope="{ row, index }" slot="action">
                        <a @click="handleReviewResume(row, index)">详情</a>
                    </template>
                </Table>
            </div>
            <div class="table-footer">
                <Row>
                    <Col class="total" span="6">共 {{ total }} 条记录 第 {{ pageNum }}/{{ pages }} 页</Col>
                    <Col class="pagenation" span="18">
                        <Page :total="total" :current="pageNum" @on-change="changePage"/>
                    </Col>
                </Row>
            </div>
        </div>
        <div slot="footer">
            <Button type="default" @click="closeModal(false)">取消</Button>
            <Button type="primary" :loading="loading" @click="handleSubmit">确定</Button>
        </div>
        <view-resume v-model="showViewResumeModal" :id="resumeId"></view-resume>
    </Modal>
</template>
<script>
import viewResume from '../../resume/view';
import Treeselect from "@riophae/vue-treeselect";
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import city from "../../../assets/area/city";
import {reqGetDictionaryListByCode} from "../../../api/system-api";
import {reqGetAvailResumes} from "../../../api/admin-api";

export default {
    name: "resumeList",
    components: {Treeselect, viewResume},
    props: {
        value: {
            type: Boolean,
            default: false
        },
        positionId: null,
        resumeList: []
    },
    data() {
        return {
            showForm: this.value,
            showViewResumeModal: false,
            loading: false,
            resumeId: null,
            total: 0,
            pages: 1,
            pageNum: 1,
            pageSize: 10,
            cityOpts: city,
            clearOnSelect: true,
            degreeArray: [],
            salaryFromArray: [],
            salaryToArray: [],
            filter: {
                keywords: null,
                gender: null,
                degree: null,
                intention: null,
                salaryFrom: null,
                salaryTo: null,
            },
            recommendedMap: new Map(),
            dataList: [],
            columns: [
                {
                    title: 'ID',
                    key: 'id',
                    width: 100,
                },
                {
                    title: '姓名',
                    key: 'name',
                    width: 100,
                },
                {
                    title: '性别',
                    key: 'gender',
                    width: 80,
                },
                {
                    title: '年龄',
                    key: 'age',
                    width: 80,
                },
                {
                    title: '手机号码',
                    key: 'phone',
                    minWidth: 120,
                },
                {
                    title: '最高学历',
                    key: 'highestDegreeName',
                    minWidth: 150,
                },
                {
                    title: '意向城市',
                    key: 'intentionName',
                    tooltip: 'true',
                    minWidth: 200,
                },
                {
                    title: '职位',
                    key: 'applyPosition',
                    minWidth: 150,
                },
                {
                    title: '操作',
                    slot: 'action',
                    width: 80,
                    align: 'center',
                    fixed: 'right',
                },
                {
                    title: '是否推荐?',
                    key: 'isRecommended',
                    width: 100,
                    render: (h, params) => {
                        return h('i-switch', {
                            props: {value: (Array.from(this.recommendedMap.keys()).includes(params.row.id))},
                            on: {
                                'on-change': (val) => {
                                    if (val) {
                                        let resume = Object.assign({}, params.row);
                                        resume.resumeId = params.row.id;
                                        this.recommendedMap.set(params.row.id, resume);
                                    } else {
                                        this.recommendedMap.delete(params.row.id);
                                    }
                                    // 重构map，否则页面不更新
                                    this.recommendedMap = new Map(this.recommendedMap);
                                }
                            }
                        }, [
                            h('span', { slot: 'open' }, '是'),
                            h('span', { slot: 'close' }, '否'),
                        ]);
                    },
                    align: 'center',
                    fixed: 'right',
                },
            ],
        }
    },
    methods: {
        getDegree() {
            reqGetDictionaryListByCode({code: "highest_degree"}).then((res) => {
                this.degreeArray = res.data.data
            }).catch(() => {
                this.degreeArray = []
            })
        },
        buildSalaryArray() {
            this.salaryFromArray = [];
            this.salaryToArray = [];
            for (let i=1; i<=500; i++) {
                this.salaryFromArray.push({id: i, name: i + '万'});
                this.salaryToArray.push({id: i, name: i + '万'});
            }
        },
        setSalaryToMax(val) {
            if ('undefined' === typeof val) {
                this.salaryToArray = [];
                for (let i=1; i<=500; i++) {
                    this.salaryToArray.push({id: i, name: i + '万'});
                }
            } else {
                this.salaryToArray = [];
                for (let i=val+1; i<=500; i++) {
                    this.salaryToArray.push({id: i, name: i + '万'});
                }
            }
        },
        getAvailResumes() {
            let that = this;
            that.loading = true;
            that.filter.pageNum = that.pageNum;
            that.filter.pageSize = that.pageSize;
            that.filter.resumeIds = that.resumeList ? that.resumeList.map(item => item.resumeId).join(',') : '';
            that.filter.positionId = that.positionId;
            reqGetAvailResumes(that.filter).then((res) => {
                this.loading = false
                that.total = res.data.data.total;
                that.pages = res.data.data.pages;
                that.dataList = res.data.data.records;
            }).catch((err) => {
                that.dataList = [];
                this.loading = false;
                console.log("err====", err);
            });
        },
        doSearching() {
            this.pageNum = 1;
            this.getAvailResumes();
        },
        handleReset() {
            this.filter = {
                keywords: null,
                gender: null,
                degree: null,
                intention: null,
                salaryFrom: null,
                salaryTo: null,
            };
            this.pageNum = 1;
            this.getAvailResumes();
        },
        changePage(val) {
            this.pageNum = val;
            this.getAvailResumes();
        },
        handleReviewResume(row, index) {
            this.showViewResumeModal = true;
            this.resumeId = row.id;
        },
        handleSubmit() {
            this.$emit('addResumeList', Array.from(this.recommendedMap.values()));
            this.closeModal(false);
        },
        closeModal(val) {
            this.$emit('input', val);
        }
    },
    watch: {
        value(val) {
            this.showForm = val;
        },
        showForm(val) {
            if (val) {
                this.getDegree();
                this.buildSalaryArray();
                this.getAvailResumes();
            } else {// 反之则关闭页面
                this.degreeArray = [];
                this.salaryFromArray = [];
                this.salaryToArray = [];
                this.dataList = [];
                this.recommendedMap = new Map();
                this.filter = {
                    keywords: null,
                    gender: null,
                    degree: null,
                    intention: null,
                    salaryFrom: null,
                    salaryTo: null,
                };
                this.closeModal(val);
            }
        }
    }
}
</script>
