<template>
    <Modal v-model="showForm" :title="modalTitle" width="640" :mask-closable="false">
        <Form class="plain-form" ref="editForm" :model="editForm" :rules="editFormRule" label-colon :label-width="100">
            <div class="custom-form">
                <div class="divider">职位信息</div>
                <div class="custom-form-section">
                    <Row :gutter="16">
                        <Col span="12">
                            <FormItem label="客户">{{ myPosition.customerName }}</FormItem>
                        </Col>
                        <Col span="12">
                            <FormItem label="职位">{{ myPosition.name }}</FormItem>
                        </Col>
                    </Row>
                    <Row :gutter="16">
                        <Col span="12">
                            <FormItem label="类型">{{ myPosition.typeName }}</FormItem>
                        </Col>
                        <Col span="12">
                            <FormItem label="级别">{{ myPosition.levelName }}</FormItem>
                        </Col>
                    </Row>
                    <Row :gutter="16">
                        <Col span="12">
                            <FormItem label="金额">{{ amount }}</FormItem>
                        </Col>
                        <Col span="12">
                            <FormItem label="顾问">{{ myPosition.consultantNames }}</FormItem>
                        </Col>
                    </Row>
                    <Row :gutter="16">
                        <Col span="12">
                            <FormItem label="联系人">{{ myPosition.contact }}</FormItem>
                        </Col>
                        <Col span="12">
                            <FormItem label="联系电话">{{ myPosition.contactPhone }}</FormItem>
                        </Col>
                    </Row>
                    <Row :gutter="16">
                        <Col span="12">
                            <FormItem label="联系人职务">{{ myPosition.contactTitle }}</FormItem>
                        </Col>
                        <Col span="12">
                            <FormItem label="创建人">{{ myPosition.createByName }}</FormItem>
                        </Col>
                    </Row>
                    <Row :gutter="16">
                        <Col span="24">
                            <FormItem label="创建时间">{{ myPosition.createTime }}</FormItem>
                        </Col>
                    </Row>
                    <Row :gutter="16">
                        <Col span="24">
                            <FormItem class="multiline-content" label="职位说明"><div v-html="myPosition.description"></div></FormItem>
                        </Col>
                    </Row>
                    <Row :gutter="16">
                        <Col span="24">
                            <FormItem class="multiline-content" label="访谈要点"><div v-html="myPosition.brief"></div></FormItem>
                        </Col>
                    </Row>
                </div>

                <div class="divider">职位共享</div>
                <div class="custom-form-section">
                    <Row :gutter="16">
                        <Col span="24">
                            <FormItem class="plain-item-margin" label="共享用户" prop="consultants">
                                <Select v-model="consultantIds" placeholder="请选择" multiple filterable clearable>
                                    <Option v-for="item in consultantArray" :value="item.id" :key="item.id">{{ item.name }}</Option>
                                </Select>
                            </FormItem>
                        </Col>
                    </Row>
                </div>
            </div>
        </Form>
        <div slot="footer">
            <Button type="default" @click="closeModal(false)">取消</Button>
            <Button type="primary" :loading="loading" @click="handleSubmit">确定</Button>
        </div>
    </Modal>
</template>
<script>
import {reqSharePosition, reqGetAvailConsultants, reqPutToPool} from "@/api/admin-api";

export default {
    name: "markPosition",
    props: {
        value: {
            type: Boolean,
            default: false
        },
        position: {}
    },
    data() {
        return {
            loading: false,
            consultantArray: [],
            showForm: this.value,
            modalTitle: null,
            amount: null,
            myPosition: {},
            editForm: {
                id: null,
                custId: null,
                name: null,
                consultants: [],
            },
            consultantIds: [],
            editFormRule:{
                consultants: [{message: '请选择共享用户', type:'array', trigger: 'blur'}]
            }
        }
    },
    methods: {
        handleSubmit() {
            if (this.editForm.consultants === null){
                this.editForm.consultants = []
            }
            for (let i = 0; i < this.consultantIds.length; i++) {
                this.editForm.consultants.push(this.consultantIds[i])
            }
            this.editForm.consultants = Array.from(new Set(this.editForm.consultants))
            if (this.editForm.consultants !== null) {
                this.$Modal.confirm({
                    title: '共享职位？',
                    content: '确认共享职位给此顾问？',
                    width: '240',
                    onOk: () => {
                        this.$refs['editForm'].validate((valid) => {
                            if (!valid) {
                                this.loading = false;
                                return false;
                            }

                            if (this.editForm.consultants.length > 9){
                                this.loading = false;
                                this.$Message.error('最多可共享人数为10人');
                                return false;
                            }
                            reqSharePosition(this.editForm).then((res) => {
                                this.loading = false;
                                if (res.data.code === 1) {
                                    this.$Message.success('共享成功');
                                    this.$emit('getPosition');
                                    this.closeModal(false);
                                } else {
                                    this.$Message.error('共享失败');
                                }
                            }).catch(()=>{
                                this.loading = false;
                                this.$Message.error('共享失败');
                            })
                        });
                    }
                })
            }else {
                this.$Message.error("请选择共享用户")
            }
        },
        getAvailConsultants() {
            reqGetAvailConsultants({id:this.editForm.id}).then((res) => {
                this.consultantArray = res.data.data || [];
                var list = [];
                for (let i = 0; i < this.consultantArray.length; i++) {
                    list.push(this.consultantArray[i].id);
                }
                var newList = this.position.consultantIds ? this.position.consultantIds.split(',') : [];
                newList = newList.length === 1 ? null : newList;
                if (newList !== null){
                    for (let i = 0; i < newList.length; i++) {
                        if (list.indexOf(newList[i]) != -1){
                            this.editForm.consultants.splice(this.editForm.consultants.indexOf(newList[i]),1)
                        }
                    }
                }
            }).catch(() => {
                this.consultantArray = [];
            })
        },
        closeModal(val) {
            this.$emit('input', val);
        }
    },
    watch: {
        value(val) {
            this.showForm = val;
        },
        showForm(val) {
            if (val) {
                this.myPosition = this.position;
                this.editForm.id = this.position.id;
                this.editForm.custId = this.position.custId;
                this.editForm.name = this.position.name;
                this.editForm.typeName = this.position.typeName;
                this.amount = this.myPosition.amount.toFixed(2);
                this.editForm.consultants = this.position.consultantIds ? this.position.consultantIds.split(',') : [];
                this.editForm.consultants = this.editForm.consultants.length === 1 ? null : this.editForm.consultants;
                this.consultantIds = this.position.consultantIds ? this.position.consultantIds.split(',') : [];
                this.modalTitle = '职位共享 (ID:' + this.position.custId + ')';
                this.getAvailConsultants();
            } else {// 反之则关闭页面
                this.closeModal(val);
            }
        }
    }
}
</script>
