<template>
    <div class="main-content">
        <div class="breadcrumb">
            <Breadcrumb>
                <BreadcrumbItem>职位管理</BreadcrumbItem>
                <BreadcrumbItem>职位列表</BreadcrumbItem>
            </Breadcrumb>
        </div>
        <div class="filter">
            <Form label-colon inline>
                <FormItem class="ivu-form-item-xl">
                    <Input v-model="filter.keywords" placeholder="输入职位名称、客户名称、联系人、顾问、职位ID" clearable/>
                </FormItem>
                <FormItem>
                    <Select v-model="filter.create" placeholder="请选择创建人" filterable clearable>
                        <Option v-for="item in createArray" :value="item.id" :key="item.id">{{ item.name }}</Option>
                    </Select>
                </FormItem>
                <FormItem>
                    <DatePicker type="daterange" v-model="filter.positionDateRange" placeholder="请选择职位创建周期" separator=" 至 " clearable></DatePicker>
                </FormItem>
                <FormItem>
                    <Select v-model="filter.type" placeholder="请选择类型" filterable clearable>
                        <Option v-for="item in dict.position_type" :value="item.id" :key="item.id">{{ item.name }}</Option>
                    </Select>
                </FormItem>
                <FormItem>
                    <Select v-model="filter.level" placeholder="请选择级别" filterable clearable>
                        <Option v-for="item in dict.position_level" :value="item.id" :key="item.id">{{ item.name }}</Option>
                    </Select>
                </FormItem>
                <FormItem>
                    <Select v-model="filter.status" placeholder="请选择状态" filterable clearable>
                        <Option v-for="item in dict.position_status" :value="item.id" :key="item.id">{{ item.name }}</Option>
                    </Select>
                </FormItem>
                <FormItem>
                    <Select v-model="filter.source" placeholder="请选择项目属性" filterable clearable>
                        <Option value="1">共享</Option>
                        <!--                        <Option value="2">共享池</Option>-->
                    </Select>
                </FormItem>
                <FormItem>
                    <TreeSelect v-model="filter.departmentId" :options="departmentOpts" placeholder="请选择所属组织" noOptionsText="暂无数据" noResultsText="没有匹配数据" clearable />
                </FormItem>
                <FormItem>
                    <Button type="primary" @click="doSearching">搜索</Button>
                </FormItem>
                <FormItem>
                    <Button type="primary" @click="resetting">重置</Button>
                </FormItem>
                <FormItem>
                    <Button type="primary" icon="md-add" @click="handleAdd" v-if="permissionButton.add">新增</Button>
                </FormItem>
                <FormItem>
                    <Button type="primary" @click="handleBatchDel" :disabled="selectedMap.size === 0" v-if="permissionButton.batchDel">批量删除</Button>
                </FormItem>
            </Form>
        </div>
        <div class="table">
            <div class="table-list">
                <Table stripe :columns="columns" :data="dataList" :loading="loading" @on-select="handleSelect"
                       @on-select-cancel="handleCancel" @on-select-all="handleSelectAll" @on-select-all-cancel="handleAllCancel">
                    <template slot-scope="{ row, index }" slot="action">
                        <Dropdown transfer>
                            <a href="javascript:void(0)">操作<Icon type="ios-arrow-down"></Icon></a>
                            <DropdownMenu class="table-dropdown" slot="list">
                                <DropdownItem v-if="permissionButton.view" @click.native="handleView(row, index)">详情</DropdownItem>
                                <DropdownItem v-if="permissionButton.edit && row.statusName === '进行中' && (row.sourceName === '0' || subordinate?.includes(row.owner))" @click.native="handleEdit(row, index)">编辑</DropdownItem>
                                <DropdownItem v-if="permissionButton.mark && row.statusName === '进行中' && (row.sourceName === '0' || subordinate?.includes(row.owner))" @click.native="handleMark(row, index)">标记</DropdownItem>
                                <DropdownItem v-if="permissionButton.share && row.statusName === '进行中' && (row.sourceName === '0' || subordinate?.includes(row.owner))  && row.isShared === 0" @click.native="handleShare(row, index)">共享</DropdownItem>
                                <DropdownItem v-if="permissionButton.pool && row.statusName === '进行中' && (row.sourceName !== '2' || subordinate?.includes(row.owner)) && row.isShared === 0" @click.native="handlePool(row, index)">共享池</DropdownItem>
                                <DropdownItem v-if="permissionButton.change && row.statusName === '进行中' && (row.sourceName === '0' || subordinate?.includes(row.owner))" @click.native="handleChange(row, index)">变更</DropdownItem>
                                <DropdownItem v-if="permissionButton.delivery && row.sourceName && row.statusName === '进行中' && row.consultantUserNames?.includes(userName)" @click.native="handleDelivery(row, index)">交付</DropdownItem>
                                <DropdownItem class="table-delete" v-if="permissionButton.del && (row.sourceName === '0' || subordinate?.includes(row.owner))" @click.native="handleDel(row, index)">删除</DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    </template>
                </Table>
            </div>
            <div class="table-footer">
                <Row>
                    <Col class="total" span="6">共 {{ total }} 条记录 第 {{ pageNum }}/{{ pages }} 页</Col>
                    <Col class="pagenation" span="18">
                        <Page :page-size="pageSize" :total="total" :current="pageNum"
                              :page-size-opts="[15, 20, 50, 100]"
                              @on-change="changePage" @on-page-size-change="changPageSize" show-sizer show-elevator/>
                    </Col>
                </Row>
            </div>
        </div>
        <add-position v-model="showAddModel" v-on:getPosition="getPosition"></add-position>
        <view-position v-model="showViewModel" :position="position"></view-position>
        <edit-position v-model="showEditModel" :id="id" :custId="custId" v-on:getPosition="getPosition"></edit-position>
        <mark-position v-model="showMarkModel" :position="position" v-on:getPosition="getPosition"></mark-position>
        <share-position v-model="showShareModel" :position="position" v-on:getPosition="getPosition"></share-position>
        <change-position v-model="showChangeModel" :position="position" v-on:getPosition="getPosition"></change-position>
        <interview v-model="showDeliveryModel" :position="position" v-on:getPosition="getPosition"></interview>
        <entry v-model="showEntryModel" :position="position" v-on:getPosition="getPosition"></entry>
        <rpo v-model="showRPOModel" :position="position" v-on:getPosition="getPosition"></rpo>
    </div>
</template>

<script>
import permission from '@/common/permission'
import {
    reqDeletePosition,
    reqPositionBatchDel,
    reqGetPosition,
    reqGetSubordinateIds,
    reqPutToPool,
    reqGetDepartmentTreeOpts
} from "@/api/admin-api";
import {reqDictionaryMapByCodeList, reqGetUserList} from "../../../api/system-api";
import addPosition from './add'
import viewPosition from './view'
import editPosition from './edit'
import markPosition from './mark'
import sharePosition from './share'
import changePosition from './change'
import interview from './interview'
import entry from './entry'
import rpo from './rpo'
import TreeSelect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";


export default {
    name: 'resumeList',
    components: {TreeSelect, addPosition, viewPosition, editPosition, markPosition, sharePosition, changePosition, interview, entry, rpo},
    data() {
        return {
            userName: "",
            showAddModel: false,
            showViewModel: false,
            showEditModel: false,
            showMarkModel: false,
            showShareModel: false,
            showChangeModel: false,
            showDeliveryModel: false,
            showEntryModel: false,
            showRPOModel: false,
            loading: false,
            subordinate: [],
            departmentOpts: [],
            createArray: [],
            id: null,
            custId: null,
            position: null,
            dict: {},
            selectedMap: new Map(),
            filter: {
                keywords: null,
                type: null,
                status: null,
                level: null,
                source: null,
                departmentId: null,
                positionDateRange: null,
                create: null
            },
            dataList: [],
            permissionButton: {
                add: false,
                edit: false,
                view: false,
                del: false,
                mark: false,
                share: false,
                pool: false,
                change: false,
                delivery: false,
                batchDel: false,
            },
            total: 0,
            pages: 1,
            pageSize: 20,
            pageNum: 1,
            columns: [
                {
                    type: 'selection',
                    width: 60,
                    align: 'center',
                },
                {
                    title: 'ID',
                    key: 'custId',
                    width: 100,
                    className: 'position-id',
                },
                {
                    title: '职位名称',
                    key: 'name',
                    tooltip: 'true',
                    minWidth: 300,
                    render: (h, params) => {
                        let valArray = [h('span', params.row.name)];
                        if (params.row.sourceName === '1' || params.row.sourceName === '2') {
                            let tagTitle = (params.row.sourceName === '1') ? '共享' : '共享池';
                            valArray.push(h('Tag', {props: {color: 'orange'}}, tagTitle));
                        }

                        return h('div', valArray);
                    }
                },
                {
                    title: '状态',
                    key: 'statusName',
                    width: 100,
                },
                {
                    title: '顾问',
                    key: 'consultantNames',
                    tooltip: 'true',
                    minWidth: 200,
                },
                {
                    title: '客户名称',
                    key: 'customerName',
                    tooltip: 'true',
                    minWidth: 300,
                },
                {
                    title: '所在地区',
                    key: 'areaName',
                    tooltip: 'true',
                    minWidth: 200,
                },
                {
                    title: '联系人',
                    key: 'contact',
                    width: 100,
                    render: (h, params) => {
                        return h('span' , params.row.contact.slice(0,1).concat('**'))
                    },
                },
                {
                    title: '联系人电话',
                    key: 'contactPhone',
                    width: 150,
                    render: (h, params) => {
                        return h('span', params.row.contactPhone.slice(0,3).concat('****',params.row.contactPhone.slice(7)))
                    },
                },
                {
                    title: '类型',
                    key: 'typeName',
                    width: 80,
                },
                {
                    title: '需求金额(元)',
                    minWidth: 120,
                    render:(h,params) =>{
                        return h('span',params.row.amount.toFixed(2))
                    }
                },
                {
                    title: '级别',
                    key: 'levelName',
                    minWidth: 80,
                },
                {
                    title: '更新时间',
                    key: 'updatedTime',
                    width: 180,
                },
                {
                    title: '操作',
                    slot: 'action',
                    width: 120,
                    align: 'center',
                    fixed: 'right',
                },
            ],
        }
    },
    methods: {
        init() {
            let promiseDict = new Promise((resolve, reject) => {
                this.dict = {};
                let codes = ['position_type', 'position_level', 'position_status'];
                reqDictionaryMapByCodeList(codes).then((res) => {
                    this.dict = res.data.data;
                    resolve(true);
                }).catch(() => {
                    this.dict = {};
                })
            });
            let promiseSubordinate = new Promise((resolve, reject) => {
                this.subordinate = [];
                reqGetSubordinateIds().then((res) => {
                    this.subordinate = res.data || [];
                    resolve(true);
                }).catch(() => {
                    this.subordinate = [];
                })
            });

            Promise.all([promiseDict, promiseSubordinate]).then(value => {
                this.getPosition();
            }).catch(reason => {
                console.log(reason);
            })
        },
        getPosition() {
            this.dataList = [];
            this.loading = true;
            let endTime =  ((this.filter.positionDateRange && this.filter.positionDateRange[1] !== '' && this.filter.positionDateRange.length > 1) ? this.$moment(this.filter.positionDateRange[1]).format('YYYY-MM-DD') : null);
            let startTime = ((this.filter.positionDateRange && this.filter.positionDateRange[0] !== '' && this.filter.positionDateRange.length > 0) ? this.$moment(this.filter.positionDateRange[0]).format('YYYY-MM-DD') : null);
            let para = {
                pageNum: this.pageNum,
                pageSize: this.pageSize,
                keywords: this.filter.keywords,
                type: this.filter.type,
                status: this.filter.status,
                level: this.filter.level,
                source: this.filter.source,
                departmentId: this.filter.departmentId,
                create: this.filter.create,
                startTime: startTime,
                endTime: endTime
            };
            reqGetPosition(para).then((res) => {
                this.loading = false;
                this.pages = res.data.data.pages
                this.total = res.data.data.total
                res.data.data.records?.forEach(item => item._disabled = (item.sourceName !== '0'));
                this.dataList = res.data.data.records;
            }).catch(() => {
                this.loading = false;
                this.dataList = [];
            })
        },
        resetting() {
            this.dataList = [];
            this.loading = true;
            this.filter.keywords = null;
            this.filter.type= null;
            this.filter.status= null;
            this.filter.level= null;
            this.filter.source= null;
            this.filter.departmentId= null;
            this.filter.positionDateRange= null;
            this.filter.create= null;
            let endTime =  ((this.filter.positionDateRange && this.filter.positionDateRange[1] !== '' && this.filter.positionDateRange.length > 1) ? this.$moment(this.filter.positionDateRange[1]).format('YYYY-MM-DD') : null);
            let startTime = ((this.filter.positionDateRange && this.filter.positionDateRange[0] !== '' && this.filter.positionDateRange.length > 0) ? this.$moment(this.filter.positionDateRange[0]).format('YYYY-MM-DD') : null);
            let para = {
                pageNum: 1,
                pageSize: 20,
                keywords: this.filter.keywords,
                type: this.filter.type,
                status: this.filter.status,
                level: this.filter.level,
                source: this.filter.source,
                departmentId: this.filter.departmentId,
                create: this.filter.create,
                startTime: startTime,
                endTime: endTime
            };
            reqGetPosition(para).then((res) => {
                this.loading = false;
                this.pages = res.data.data.pages
                this.total = res.data.data.total
                res.data.data.records?.forEach(item => item._disabled = (item.sourceName !== '0'));
                this.dataList = res.data.data.records;
            }).catch(() => {
                this.loading = false;
                this.dataList = [];
            })
        },
        handleSelect(selection, row) {
            this.selectedMap.set(row.id, row.name + '(ID:' + row.id + ')');
            // 重构map，否则页面不更新
            this.selectedMap = new Map(this.selectedMap);
        },
        handleCancel(selection, row) {
            this.selectedMap.delete(row.id);
            // 重构map，否则页面不更新
            this.selectedMap = new Map(this.selectedMap);
        },
        handleSelectAll(selection) {
            selection.forEach((item) => {
                this.selectedMap.set(item.id, item.name + '(ID:' + item.id + ')');
            });
            // 重构map，否则页面不更新
            this.selectedMap = new Map(this.selectedMap);
        },
        handleAllCancel(selection) {
            this.dataList.forEach((item) => {
                this.selectedMap.delete(item.id);
            });
            // 重构map，否则页面不更新
            this.selectedMap = new Map(this.selectedMap);
        },
        handleAdd() {
            this.showAddModel = true;
        },
        handleEdit(row, index) {
            this.id = row.id;
            this.custId = row.custId;
            this.showEditModel = true;
        },
        handleView(row, index) {
            this.position = row;
            this.showViewModel = true;
        },
        handleMark(row, index) {
            this.position = row;
            this.showMarkModel = true;
        },
        handleShare(row, index) {
            this.position = row;
            this.showShareModel = true;
        },
        handlePool(row, index) {
            this.$Modal.confirm({
                title: '共享池',
                content: '确定将该职位放入共享池？',
                width: '240',
                onOk: () => {
                    reqPutToPool({id: row.id, isShared: 1}).then((res) => {
                        if (res.data.code === 1) {
                            this.$Message.success('操作成功');
                            this.getPosition();
                        } else {
                            this.$Message.error('操作失败');
                        }
                    }).catch(()=>{
                        this.$Message.error('操作失败');
                    })
                }
            })
        },
        handleChange(row, index) {
            this.position = row;
            this.showChangeModel = true;
        },
        handleDelivery(row, index) {
            this.position = row;
            if (row.typeName === '面试') {
                this.showDeliveryModel = true;
            }
            if (row.typeName === '入职') {
                this.showEntryModel = true;
            }
            if (row.typeName === 'RPO' || row.typeName === '其他') {
                this.showRPOModel = true;
            }
        },
        handleDel(row, index) {
            this.$Modal.confirm({
                title: '删除职位',
                content: '确定删除职位 '+ row.custId +'？',
                width: '240',
                onOk: () => {
                    reqDeletePosition({id: row.id}).then((res) => {
                        if (res.data.code === 1) {
                            this.getPosition();
                            this.$Message.success('删除成功');
                        } else {
                            this.$Message.error('删除失败');
                        }
                    })
                }
            })
        },
        handleBatchDel() {
            this.$Modal.confirm({
                title: '批量删除',
                content: '确定批量删除所选职位？此操作不可逆。',
                width: '320',
                onOk: () => {
                    let params =  Array.from(this.selectedMap.keys())
                    reqPositionBatchDel(params).then((res) => {
                        if (res.data.code === 1) {
                            this.getPosition();
                            this.$Message.success('批量删除成功');
                            this.selectedMap.clear();
                        } else {
                            this.$Message.error('批量删除失败');
                        }
                    })
                },
            })
        },
        //分页点击，切换页面
        changePage(val) {
            this.pageNum = val;
            this.getPosition();
        },
        //每页显示的数据条数
        changPageSize(numOfRows) {
            //实时获取当前需要显示的条数
            this.pageSize = numOfRows;
            this.getPosition();
        },
        getDepartmentTreeOpts() {
            reqGetDepartmentTreeOpts().then(res => {
                this.departmentOpts = res.data.data;
            }).catch(()=>{
                this.departmentOpts = [];
            });
        },
        getUserList(){
            reqGetUserList().then((res) => {
                this.createArray = res.data;
            }).catch(() => {
                this.createArray = [];
            })
        },
        doSearching() {
            this.pageNum = 1;
            this.getPosition();
        }
    },
    mounted() {
        let permissionId = this.$route.query.permissionId
        permission.getPermissionButton(permissionId, this.permissionButton)
        this.userName = sessionStorage.getItem("un")
        this.init();
        this.getDepartmentTreeOpts();
        this.getUserList();
    }
}
</script>
