<template>
    <Modal v-model="showForm" :title="modalTitle" width="960" :mask-closable="false">
        <Form class="plain-form" ref="editForm" :model="editForm" :rules="editFormRule" label-colon :label-width="90">
            <div class="custom-form">
                <Alert type="warning" show-icon v-for="(error, index) in errorArray" :key="index">{{error}}</Alert>

                <div class="divider">职位信息</div>
                <div class="custom-form-section">
                    <Row :gutter="16">
                        <Col span="12">
                            <FormItem label="客户">{{ myPosition.customerName }}</FormItem>
                        </Col>
                        <Col span="12">
                            <FormItem label="职位">{{ myPosition.name }}</FormItem>
                        </Col>
                    </Row>
                    <Row :gutter="16">
                        <Col span="12">
                            <FormItem label="类型">{{ myPosition.typeName }}</FormItem>
                        </Col>
                        <Col span="12">
                            <FormItem label="级别">{{ myPosition.levelName }}</FormItem>
                        </Col>
                    </Row>
                    <Row :gutter="16">
                        <Col span="12">
                            <FormItem label="金额">{{ amount }}</FormItem>
                        </Col>
                        <Col span="12">
                            <FormItem label="顾问">{{ myPosition.consultantNames }}</FormItem>
                        </Col>
                    </Row>
                    <Row :gutter="16">
                        <Col span="12">
                            <FormItem label="联系人">{{ myPosition.contact }}</FormItem>
                        </Col>
                        <Col span="12">
                            <FormItem label="联系电话">{{ myPosition.contactPhone }}</FormItem>
                        </Col>
                    </Row>
                    <Row :gutter="16">
                        <Col span="12">
                            <FormItem label="联系人职务">{{ myPosition.contactTitle }}</FormItem>
                        </Col>
                        <Col span="12">
                            <FormItem label="创建人">{{ myPosition.createByName }}</FormItem>
                        </Col>
                    </Row>
                    <Row :gutter="16">
                        <Col span="24">
                            <FormItem label="创建时间">{{ myPosition.createTime }}</FormItem>
                        </Col>
                    </Row>
                </div>

                <div class="divider">交付进度<span class="tips">注：录入业绩提审后，之前步骤不可编辑</span></div>
                <div class="custom-form-section">
                    <Tabs type="card" v-model="currentStep" size="small" @on-click="handleStepChange" :animated="false">
                        <TabPane label="1. 推荐简历" name="recommend">
                            <div class="filter">
                                <Form ref="filter" label-colon inline>
                                    <FormItem>
                                        <Input v-model="filter.keywords" placeholder="请输入ID，姓名，手机号" clearable/>
                                    </FormItem>
                                    <FormItem>
                                        <Button type="primary" @click="handleSearchResume">搜索</Button>
                                        <Button type="primary" @click="handleFindResume" v-if="!isPayReadOnly">新增</Button>
                                    </FormItem>
                                </Form>
                            </div>
                            <Table stripe :columns="copyOfColumns" :data="copyOfRecommendResumeList">
                                <template slot-scope="{ row, index }" slot="action">
                                    <a @click="handleReviewResume(row, index)">详情</a>
                                    <Divider type="vertical"/>
                                    <a @click="handleDelResume(row, index)" v-if="!isPayReadOnly">删除</a>
                                </template>
                            </Table>
                            <div class="tabs-form">
                                <FormItem label="备注">
                                    <Input type="textarea" v-model="editForm.recommendMemo" :maxlength="500" :autosize="{minRows: 2,maxRows: 5}" show-word-limit :disabled="isPayReadOnly" />
                                </FormItem>
                            </div>
                        </TabPane>
                        <TabPane label="2. 初试" name="firstRound">
                            <div class="filter">
                                <Form ref="filter" label-colon inline>
                                    <FormItem>
                                        <Input v-model="filter.keywords" placeholder="请输入ID，姓名，手机号" clearable/>
                                    </FormItem>
                                    <FormItem>
                                        <Button type="primary" @click="handleSearchFirstRound">搜索</Button>
                                    </FormItem>
                                </Form>
                            </div>
                            <Table stripe :columns="copyOfColumns" :data="firstRoundResumeList"></Table>
                            <div class="tabs-form">
                                <FormItem label="备注">
                                    <Input type="textarea" v-model="editForm.firstRoundMemo" :maxlength="500"  :autosize="{minRows: 2,maxRows: 5}" show-word-limit :disabled="isPayReadOnly" />
                                </FormItem>
                            </div>
                        </TabPane>
                        <TabPane label="3. 复试" name="secondRound">
                            <div class="filter">
                                <Form ref="filter" label-colon inline>
                                    <FormItem>
                                        <Input v-model="filter.keywords" placeholder="请输入ID，姓名，手机号" clearable/>
                                    </FormItem>
                                    <FormItem>
                                        <Button type="primary" @click="handleSearchSecondRound">搜索</Button>
                                    </FormItem>
                                </Form>
                            </div>
                            <Table stripe :columns="copyOfColumns" :data="secondRoundResumeList"></Table>
                            <div class="tabs-form">
                                <FormItem label="备注">
                                    <Input type="textarea" v-model="editForm.secondRoundMemo" :maxlength="500" :autosize="{minRows: 2,maxRows: 5}" show-word-limit :disabled="isPayReadOnly" />
                                </FormItem>
                            </div>
                        </TabPane>
                        <TabPane label="4. Offer" name="offer">
                            <div class="filter">
                                <Form ref="filter" label-colon inline>
                                    <FormItem>
                                        <Input v-model="filter.keywords" placeholder="请输入ID，姓名，手机号" clearable/>
                                    </FormItem>
                                    <FormItem>
                                        <Button type="primary" @click="handleSearchOffer">搜索</Button>
                                    </FormItem>
                                </Form>
                            </div>
                            <Table stripe :columns="copyOfColumns" :data="offerResumeList"></Table>
                            <div class="tabs-form">
                                <FormItem label="备注">
                                    <Input type="textarea" v-model="editForm.offerMemo" :maxlength="500" :autosize="{minRows: 2,maxRows: 5}" show-word-limit :disabled="isPayReadOnly" />
                                </FormItem>
                            </div>
                        </TabPane>
                        <TabPane label="5. 入职" name="entry">
                            <div class="filter">
                                <Form ref="filter" label-colon inline>
                                    <FormItem>
                                        <Input v-model="filter.keywords" placeholder="请输入ID，姓名，手机号" clearable/>
                                    </FormItem>
                                    <FormItem>
                                        <Button type="primary" @click="handleSearchEntry">搜索</Button>
                                    </FormItem>
                                </Form>
                            </div>
                            <Table stripe :columns="copyOfColumns" :data="entryResumeList"></Table>
                            <div class="tabs-form">
                                <FormItem label="备注">
                                    <Input type="textarea" v-model="editForm.entryMemo" :maxlength="500" :autosize="{minRows: 2,maxRows: 5}" show-word-limit :disabled="isPayReadOnly" />
                                </FormItem>
                            </div>
                        </TabPane>
                        <TabPane label="6. 首付款" name="payment">
                            <div class="filter">
                                <Form ref="filter" label-colon inline>
                                    <FormItem>
                                        <Input v-model="filter.keywords" placeholder="请输入ID，姓名，手机号" clearable/>
                                    </FormItem>
                                    <FormItem>
                                        <Button type="primary" @click="handleSearchPayment">搜索</Button>
                                    </FormItem>
                                </Form>
                            </div>
                            <Table stripe :columns="copyOfColumns" :data="paymentResumeList"></Table>
                            <div class="tabs-form">
                                <FormItem label="金额" required>
                                    <Input v-model="editForm.payment" placeholder="请输入" :disabled="isPayReadOnly"></Input>
                                </FormItem>
                                <FormItem label="备注">
                                    <Input type="textarea" v-model="editForm.paymentMemo" :maxlength="500" :autosize="{minRows: 2,maxRows: 5}" show-word-limit :disabled="isPayReadOnly" />
                                </FormItem>
                                <FormItem label="附件" required>
                                    <Upload multiple type="drag"
                                            :headers="header"
                                            :max-size="10 * 1024"
                                            :action="uploadFiles"
                                            :data="{type: 'private'}"
                                            :show-upload-list="false"
                                            :format="['jpg', 'png', 'JPG', 'JPEG']"
                                            accept=".jpg,.png,.JPG,.JPEG"
                                            :on-exceeded-size="handExceeded"
                                            :on-success="(res, file, fileList) => handleSuccess(res, file, fileList, 'paymentFileArray')"
                                            v-if="!isPayReadOnly">
                                        <div class="upload-btn-l">
                                            <Icon type="md-cloud-upload"></Icon>
                                            <p class="tips">注意：支持.jpg, .jpeg, .png格式, 10M以内</p>
                                        </div>
                                    </Upload>
                                    <div class="upload-image-list" v-for="(item, index) in paymentFileArray">
                                        <img :src="item.imgData" style=""/>
                                        <div class="upload-image-list-cover">
                                            <Icon type="ios-eye" @click.native="handlePreview(item.imgData)"></Icon>
                                            <Icon type="ios-trash" @click.native="handleRemove('paymentFileArray', index)" v-if="!isPayReadOnly"></Icon>
                                        </div>
                                    </div>
                                </FormItem>
                            </div>
                        </TabPane>
                        <TabPane label="7. 录入业绩" name="performance">
                            <Table stripe :columns="copyOfColumns" :data="performanceResumeList"></Table>
                            <div class="tabs-form">
                                <FormItem label="业绩金额" required>
                                    <Input v-model="editForm.performance" placeholder="请输入" :disabled="isPayReadOnly"></Input>
                                </FormItem>
                                <FormItem label="完成时间" required>
                                    <DatePicker type="date" placeholder="请选择" v-model="editForm.completionTime" :editable="false" :disabled="isPayReadOnly"></DatePicker>
                                </FormItem>
                                <FormItem label="备注">
                                    <Input type="textarea" v-model="editForm.performanceMemo" :maxlength="500" :autosize="{minRows: 2,maxRows: 5}" show-word-limit :disabled="isPayReadOnly" />
                                </FormItem>
                                <FormItem class="plain-item" label="审批进度">
                                    <Timeline>
                                        <TimelineItem v-for="(item, index) in approvalHistoryArray" :value="index" :key="index">
                                            <div class="status">{{ item.status }} {{ item.comment }}</div>
                                            <div class="operator">{{ item.operatorName }} {{ item.updatedTime }}</div>
                                        </TimelineItem>
                                    </Timeline>
                                </FormItem>
                            </div>
                        </TabPane>
                        <TabPane label="8. 过保" name="overDue">
                            <Table stripe :columns="copyOfColumns" :data="overDueResumeList"></Table>
                            <div class="tabs-form">
                                <FormItem label="备注">
                                    <Input type="textarea" v-model="editForm.overDueMemo" :maxlength="500" :autosize="{minRows: 2,maxRows: 5}" show-word-limit :disabled="isBalReadOnly" />
                                </FormItem>
                                <FormItem label="附件" required>
                                    <Upload multiple type="drag"
                                            :headers="header"
                                            :max-size="10 * 1024"
                                            :action="uploadFiles"
                                            :data="{type: 'private'}"
                                            :show-upload-list="false"
                                            :format="['jpg', 'png', 'JPG', 'JPEG']"
                                            accept=".jpg,.png,.JPG,.JPEG"
                                            :on-exceeded-size="handExceeded"
                                            :on-success="(res, file, fileList) => handleSuccess(res, file, fileList, 'overDueFileArray')"
                                            v-if="!isBalReadOnly">
                                        <div class="upload-btn-l">
                                            <Icon type="md-cloud-upload"></Icon>
                                            <p class="tips">注意：支持.jpg, .jpeg, .png格式, 10M以内</p>
                                        </div>
                                    </Upload>
                                    <div class="upload-image-list" v-for="(item, index) in overDueFileArray">
                                        <img :src="item.imgData" style=""/>
                                        <div class="upload-image-list-cover">
                                            <Icon type="ios-eye" @click.native="handlePreview(item.imgData)"></Icon>
                                            <Icon type="ios-trash" @click.native="handleRemove('overDueFileArray', index)" v-if="!isBalReadOnly"></Icon>
                                        </div>
                                    </div>
                                </FormItem>
                            </div>
                        </TabPane>
                        <TabPane label="9. 尾款" name="balance">
                            <Table stripe :columns="copyOfColumns" :data="balanceResumeList"></Table>
                            <div class="tabs-form">
                                <FormItem label="金额" required>
                                    <Input v-model="editForm.balance" placeholder="请输入" :disabled="isBalReadOnly"></Input>
                                </FormItem>
                                <FormItem label="备注">
                                    <Input type="textarea" v-model="editForm.balanceMemo" :maxlength="500" :autosize="{minRows: 2,maxRows: 5}" show-word-limit :disabled="isBalReadOnly" />
                                </FormItem>
                                <FormItem label="附件" required>
                                    <Upload multiple type="drag"
                                            :headers="header"
                                            :max-size="10 * 1024"
                                            :action="uploadFiles"
                                            :data="{type: 'private'}"
                                            :show-upload-list="false"
                                            :format="['jpg', 'png', 'JPG', 'JPEG']"
                                            accept=".jpg,.png,.JPG,.JPEG"
                                            :on-exceeded-size="handExceeded"
                                            :on-success="(res, file, fileList) => handleSuccess(res, file, fileList, 'balanceFileArray')"
                                            v-if="!isBalReadOnly">
                                        <div class="upload-btn-l">
                                            <Icon type="md-cloud-upload"></Icon>
                                            <p class="tips">注意：支持.jpg, .jpeg, .png格式, 10M以内</p>
                                        </div>
                                    </Upload>
                                    <div class="upload-image-list" v-for="(item, index) in balanceFileArray">
                                        <img :src="item.imgData" style=""/>
                                        <div class="upload-image-list-cover">
                                            <Icon type="ios-eye" @click.native="handlePreview(item.imgData)"></Icon>
                                            <Icon type="ios-trash" @click.native="handleRemove('balanceFileArray', index)" v-if="!isBalReadOnly"></Icon>
                                        </div>
                                    </div>
                                </FormItem>
                            </div>
                        </TabPane>
                        <TabPane label="10. 录入业绩" name="balPerformance">
                            <Table stripe :columns="copyOfColumns" :data="balPerformanceResumeList"></Table>
                            <div class="tabs-form">
                                <FormItem label="业绩金额" required>
                                    <Input v-model="editForm.balPerformance" placeholder="请输入" :disabled="isBalReadOnly"></Input>
                                </FormItem>
                                <FormItem label="完成时间" required>
                                    <DatePicker type="date" placeholder="请选择" v-model="editForm.balPerformanceTime" :editable="false" :disabled="isBalReadOnly"></DatePicker>
                                </FormItem>
                                <FormItem label="备注">
                                    <Input type="textarea" v-model="editForm.balPerformanceMemo" :maxlength="500" :autosize="{minRows: 2,maxRows: 5}" show-word-limit :disabled="isBalReadOnly" />
                                </FormItem>
                                <FormItem class="plain-item" label="审批进度">
                                    <Timeline>
                                        <TimelineItem v-for="(item, index) in balApprovalHistoryArray" :value="index" :key="index">
                                            <div class="status">{{ item.status }}<span v-if="item.comment !== null">{{ item.comment }}</span></div>
                                            <div class="operator">{{ item.operatorName }} {{ item.updatedTime }}</div>
                                        </TimelineItem>
                                    </Timeline>
                                </FormItem>
                            </div>
                        </TabPane>
                    </Tabs>
                </div>
            </div>
        </Form>
        <Modal title="查看图片" v-model="showPreview" footer-hide :width="640">
            <img :src="imgView" v-if="showPreview" style="width: 100%"/>
        </Modal>
        <div slot="footer">
            <Button type="default" @click="closeModal(false)">取消</Button>
            <Button type="primary" :loading="loading" @click="handleSubmit('save')" v-if="!isPayReadOnly && (currentStep === 'recommend' || currentStep === 'firstRound' || currentStep === 'secondRound' || currentStep === 'offer' || currentStep === 'entry' || currentStep === 'payment')">提交</Button>
            <Button type="primary" :loading="loading" @click="handleSubmit('approval')" v-if="!isPayReadOnly && currentStep === 'performance'">提审</Button>
            <Button type="primary" :loading="loading" @click="handleSubmit('saveBal')" v-if="!isBalReadOnly && (currentStep === 'overDue' || currentStep === 'balance')">提交</Button>
            <Button type="primary" :loading="loading" @click="handleSubmit('approvalBal')" v-if="!isBalReadOnly && currentStep === 'balPerformance'">提审</Button>
        </div>
        <view-resume v-model="showViewResumeModal" :id="resumeId"></view-resume>
        <resume-list v-model="showResumeListModal" :positionId="myPosition.id" :resumeList="recommendResumeList" v-on:addResumeList="addResumeList"></resume-list>
    </Modal>
</template>
<script>
import {
    reqGetOpenDelivery,
    reqSubmitDelivery,
    reqGetResumesInDelivery, reqGetApprovalHistoryByDeliveryId
} from "../../../api/admin-api";
import resumeList from './resumeList';
import viewResume from '../../resume/view';
import {downloadFile, reqDeleteFiles, uploadFileURL} from "../../../api/system-api";

export default {
    components: {viewResume, resumeList},
    props: {
        value: {
            type: Boolean,
            default: false
        },
        position: {}
    },
    data() {
        return {
            amount: null,
            showForm: this.value,
            showResumeListModal: false,
            showViewResumeModal: false,
            modalTitle: null,
            loading: false,
            myPosition: {typeName: ''},
            resumeId: null,
            currentStep: 'recommend',
            header: null,
            uploadFiles: uploadFileURL,
            paymentFileArray: [],
            overDueFileArray: [],
            balanceFileArray: [],
            showPreview: false,
            imgView: null,
            isPayReadOnly: false,
            isBalReadOnly: false,
            errorArray: [],
            filter: {
                keywords: null,
            },
            editForm: {
                id: null,
                positionId: null,
                recommendMemo: null,
                firstRoundMemo: null,
                secondRoundMemo: null,
                offerMemo: null,
                entryMemo: null,
                payment: null,
                paymentMemo: null,
                performance: null,
                performanceMemo: null,
                overDueMemo: null,
                balanceMemo: null,
                completionTime: null,
                balPerformance: null,
                balPerformanceMemo: null,
                balPerformanceTime: null,
            },
            editFormRule:{},
            resumes: [],
            recommendResumeList: [],
            copyOfRecommendResumeList: [],
            firstRoundResumeList: [],
            selectedFirstRoundResumeList: [],
            secondRoundResumeList: [],
            selectedSecondRoundResumeList: [],
            offerResumeList: [],
            selectedOfferResumeList: [],
            entryResumeList: [],
            selectedEntryResumeList: [],
            paymentResumeList: [],
            selectedPaymentResumeList: [],
            performanceResumeList: [],
            balPerformanceResumeList: [],
            overDueResumeList: [],
            balanceResumeList: [],
            copyOfColumns: [],
            approvalHistoryArray: [],
            balApprovalHistoryArray: [],
            columns: [
                {
                    type: 'index',
                    width: 80,
                    align: 'center',
                    title: '序号'
                },
                {
                    title: 'ID',
                    key: 'resumeId',
                    width: 80,
                },
                {
                    title: '姓名',
                    key: 'name',
                    width: 100,
                },
                {
                    title: '性别',
                    key: 'gender',
                    width: 80,
                },
                {
                    title: '年龄',
                    key: 'age',
                    width: 80,
                },
                {
                    title: '手机号码',
                    key: 'phone',
                    minWidth: 120,
                },
                {
                    title: '最高学历',
                    key: 'highestDegreeName',
                    minWidth: 120,
                }
            ],
        }
    },
    methods: {
        init() {
            let token = sessionStorage.getItem('Authorization');
            this.header = {Authorization: token};
            this.paymentFileArray = [];
            this.overDueFileArray = [];
            this.balanceFileArray = [];
            this.showPreview = false;
            this.isPayReadOnly = false;
            this.isBalReadOnly = false;
            this.imgView = null;

            let promiseResume = new Promise((resolve, reject) => {
                reqGetResumesInDelivery({positionId: this.myPosition.id}).then((res) => {
                    this.resumes = res.data.data || [];

                    this.recommendResumeList = JSON.parse(JSON.stringify(this.resumes)).filter(item => item.type === 'recommend');
                    this.copyOfRecommendResumeList = JSON.parse(JSON.stringify(this.recommendResumeList));

                    this.firstRoundResumeList = JSON.parse(JSON.stringify(this.recommendResumeList));
                    this.selectedFirstRoundResumeList = JSON.parse(JSON.stringify(this.resumes)).filter(item => item.type === 'firstRound');

                    this.secondRoundResumeList = JSON.parse(JSON.stringify(this.selectedFirstRoundResumeList));
                    this.selectedSecondRoundResumeList = JSON.parse(JSON.stringify(this.resumes)).filter(item => item.type === 'secondRound');

                    this.offerResumeList = JSON.parse(JSON.stringify(this.selectedSecondRoundResumeList));
                    this.selectedOfferResumeList = JSON.parse(JSON.stringify(this.resumes)).filter(item => item.type === 'offer');

                    this.entryResumeList = JSON.parse(JSON.stringify(this.selectedOfferResumeList));
                    this.selectedEntryResumeList = JSON.parse(JSON.stringify(this.resumes)).filter(item => item.type === 'entry');

                    this.paymentResumeList = JSON.parse(JSON.stringify(this.selectedEntryResumeList));
                    this.selectedPaymentResumeList = JSON.parse(JSON.stringify(this.resumes)).filter(item => item.type === 'payment');

                    this.performanceResumeList = JSON.parse(JSON.stringify(this.resumes)).filter(item => item.type === 'performance');
                    this.overDueResumeList = JSON.parse(JSON.stringify(this.resumes)).filter(item => item.type === 'overDue');
                    this.balanceResumeList = JSON.parse(JSON.stringify(this.resumes)).filter(item => item.type === 'balance');
                    this.balPerformanceResumeList = JSON.parse(JSON.stringify(this.resumes)).filter(item => item.type === 'balPerformance');

                    resolve(true);
                }).catch(() => {
                    this.recommendResumeList = [];
                    this.copyOfRecommendResumeList = [];
                    this.firstRoundResumeList = [];
                    this.selectedFirstRoundResumeList = [];
                    this.secondRoundResumeList = [];
                    this.selectedSecondRoundResumeList = [];
                    this.offerResumeList = [];
                    this.selectedOfferResumeList = [];
                    this.entryResumeList = [];
                    this.selectedEntryResumeList = [];
                    this.paymentResumeList = [];
                    this.selectedPaymentResumeList = [];
                    this.performanceResumeList = [];
                    this.balPerformanceResumeList = [];
                    this.overDueResumeList = [];
                    this.balanceResumeList = [];
                })
            });
            let promiseSummary = new Promise((resolve, reject) => {
                reqGetOpenDelivery({id: this.myPosition.id}).then((res) => {
                    this.editForm = res.data.data || {};

                    this.editForm.paymentFile && JSON.parse(this.editForm.paymentFile).forEach(name => {
                        downloadFile({fileName: name, type: 'private'}).then((res2) => {
                            let myData = window.URL.createObjectURL(new Blob([res2.data]));
                            this.paymentFileArray.push({imgName: name, imgData: myData});
                        });
                    });

                    this.editForm.overDueFile && JSON.parse(this.editForm.overDueFile).forEach(name => {
                        downloadFile({fileName: name, type: 'private'}).then((res2) => {
                            let myData = window.URL.createObjectURL(new Blob([res2.data]));
                            this.overDueFileArray.push({imgName: name, imgData: myData});
                        });
                    });

                    this.editForm.balanceFile && JSON.parse(this.editForm.balanceFile).forEach(name => {
                        downloadFile({fileName: name, type: 'private'}).then((res2) => {
                            let myData = window.URL.createObjectURL(new Blob([res2.data]));
                            this.balanceFileArray.push({imgName: name, imgData: myData});
                        });
                    });

                    resolve(true);
                }).catch((err) => {
                    console.log('err======', err)
                    this.editForm = {};
                })
            });

            Promise.all([promiseSummary, promiseResume]).then(value => {
                reqGetApprovalHistoryByDeliveryId({deliveryId: this.editForm.id}).then((res) => {
                    this.approvalHistoryArray = res.data.data.filter(item => item.type === '录入业绩');
                    this.balApprovalHistoryArray = res.data.data.filter(item => item.type === '尾款业绩');
                }).catch((err) => {
                    this.approvalHistoryArray = [];
                    this.balApprovalHistoryArray = [];
                })

                // 首付款信息只读？
                this.isPayReadOnly = (this.editForm.approvalStatus > 1);
                // 尾付款款信息只读？
                this.isBalReadOnly = !(this.editForm.approvalStatus === 4 || this.editForm.approvalStatus === 5);

                this.currentStep = 'recommend';
                if (this.editForm.approvalStatus === 1 || this.editForm.approvalStatus === 2 || this.editForm.approvalStatus === 3) {
                    this.currentStep = 'performance';
                }
                if (this.editForm.approvalStatus === 4) {
                    this.currentStep = 'overDue';
                }
                if (this.editForm.approvalStatus === 5 || this.editForm.approvalStatus === 6 || this.editForm.approvalStatus === 7) {
                    this.currentStep = 'balPerformance';
                }

                this.handleStepChange(this.currentStep);
            }).catch(reason => {
                console.log(reason);
            })
        },
        setColumns() {
            let myColumns = JSON.parse(JSON.stringify(this.columns));
            if (this.currentStep === 'recommend') {
                myColumns.push({
                    title: '操作',
                    key: 'action',
                    align: 'center',
                    width: 120,
                    render: (h, params, index) => {
                        let content = [h('a', {on: {'click': () => {this.handleReviewResume(params.row, index);}}}, '详情')];
                        if (!this.isPayReadOnly) {
                            content.push(h('Divider', {props: {type: 'vertical'}}));
                            content.push(h('a', {on: {'click': () => {this.handleDelResume(params.row, index);}}}, '删除'));
                        }
                        return h('div', content);
                    }
                });
            }
            if (this.currentStep === 'firstRound') {
                myColumns.push({
                    title: '操作',
                    key: 'action',
                    align: 'center',
                    width: 80,
                    render: (h, params, index) => {
                        let content = [h('a', {on: {'click': () => {this.handleReviewResume(params.row, index);}}}, '详情')];
                        return h('div', content);
                    }
                },{
                    title: '是否初试？',
                    key: 'action2',
                    align: 'center',
                    width: 120,
                    render: (h, params) => {
                        return h('i-switch', {
                            props: {value: this.selectedFirstRoundResumeList.findIndex(item => item.resumeId === params.row.resumeId) >= 0, disabled: this.isPayReadOnly},
                            on: {
                                'on-change': (val) => {
                                    if (val) {
                                        this.selectedFirstRoundResumeList.push(params.row);
                                    } else {
                                        let idx = this.selectedFirstRoundResumeList.findIndex(item => item.resumeId === params.row.resumeId);
                                        if (idx >= 0) {
                                            this.selectedFirstRoundResumeList.splice(idx,1);
                                        }
                                    }
                                }
                            }
                        }, [
                            h('span', { slot: 'open' }, '是'),
                            h('span', { slot: 'close' }, '否'),
                        ]);
                    }
                });
            }
            if (this.currentStep === 'secondRound') {
                myColumns.push({
                    title: '操作',
                    key: 'action',
                    align: 'center',
                    width: 80,
                    render: (h, params, index) => {
                        let content = [h('a', {on: {'click': () => {this.handleReviewResume(params.row, index);}}}, '详情')];
                        return h('div', content);
                    }
                },{
                    title: '是否复试？',
                    key: 'action2',
                    align: 'center',
                    width: 120,
                    render: (h, params) => {
                        return h('i-switch', {
                            props: {value: this.selectedSecondRoundResumeList.findIndex(item => item.resumeId === params.row.resumeId) >= 0, disabled: this.isPayReadOnly},
                            on: {
                                'on-change': (val) => {
                                    if (val) {
                                        this.selectedSecondRoundResumeList.push(params.row);
                                    } else {
                                        let idx = this.selectedSecondRoundResumeList.findIndex(item => item.resumeId === params.row.resumeId);
                                        if (idx >= 0) {
                                            this.selectedSecondRoundResumeList.splice(idx,1);
                                        }
                                    }
                                }
                            }
                        }, [
                            h('span', { slot: 'open' }, '是'),
                            h('span', { slot: 'close' }, '否'),
                        ]);
                    }
                });
            }
            if (this.currentStep === 'offer') {
                myColumns.push({
                    title: '操作',
                    key: 'action',
                    align: 'center',
                    width: 80,
                    render: (h, params, index) => {
                        let content = [h('a', {on: {'click': () => {this.handleReviewResume(params.row, index);}}}, '详情')];
                        return h('div', content);
                    }
                },{
                    title: '已发Offer？',
                    key: 'action2',
                    align: 'center',
                    width: 120,
                    render: (h, params) => {
                        return h('i-switch', {
                            props: {value: this.selectedOfferResumeList.findIndex(item => item.resumeId === params.row.resumeId) >= 0, disabled: this.isPayReadOnly},
                            on: {
                                'on-change': (val) => {
                                    if (val) {
                                        this.selectedOfferResumeList.push(params.row);
                                    } else {
                                        let idx = this.selectedOfferResumeList.findIndex(item => item.resumeId === params.row.resumeId);
                                        if (idx >= 0) {
                                            this.selectedOfferResumeList.splice(idx,1);
                                        }
                                    }
                                }
                            }
                        }, [
                            h('span', { slot: 'open' }, '是'),
                            h('span', { slot: 'close' }, '否'),
                        ]);
                    }
                });
            }
            if (this.currentStep === 'entry') {
                myColumns.push({
                    title: '操作',
                    key: 'action',
                    align: 'center',
                    width: 80,
                    render: (h, params, index) => {
                        let content = [h('a', {on: {'click': () => {this.handleReviewResume(params.row, index);}}}, '详情')];
                        return h('div', content);
                    }
                },{
                    title: '已入职？',
                    key: 'action2',
                    align: 'center',
                    width: 120,
                    render: (h, params) => {
                        return h('i-switch', {
                            props: {value: this.selectedEntryResumeList.findIndex(item => item.resumeId === params.row.resumeId) >= 0, disabled: this.isPayReadOnly},
                            on: {
                                'on-change': (val) => {
                                    if (val) {
                                        this.selectedEntryResumeList.push(params.row);
                                    } else {
                                        let idx = this.selectedEntryResumeList.findIndex(item => item.resumeId === params.row.resumeId);
                                        if (idx >= 0) {
                                            this.selectedEntryResumeList.splice(idx,1);
                                        }
                                    }
                                }
                            }
                        }, [
                            h('span', { slot: 'open' }, '是'),
                            h('span', { slot: 'close' }, '否'),
                        ]);
                    }
                });
            }
            if (this.currentStep === 'payment') {
                myColumns.push({
                    title: '操作',
                    key: 'action',
                    align: 'center',
                    width: 80,
                    render: (h, params, index) => {
                        let content = [h('a', {on: {'click': () => {this.handleReviewResume(params.row, index);}}}, '详情')];
                        return h('div', content);
                    }
                },{
                    title: '已付首付款？',
                    key: 'action2',
                    align: 'center',
                    width: 120,
                    render: (h, params) => {
                        return h('i-switch', {
                            props: {value: this.selectedPaymentResumeList.findIndex(item => item.resumeId === params.row.resumeId) >= 0, disabled: this.isPayReadOnly},
                            on: {
                                'on-change': (val) => {
                                    if (val) {
                                        this.selectedPaymentResumeList.push(params.row);
                                    } else {
                                        let idx = this.selectedPaymentResumeList.findIndex(item => item.resumeId === params.row.resumeId);
                                        if (idx >= 0) {
                                            this.selectedPaymentResumeList.splice(idx,1);
                                        }
                                    }
                                }
                            }
                        }, [
                            h('span', { slot: 'open' }, '是'),
                            h('span', { slot: 'close' }, '否'),
                        ]);
                    }
                });
            }
            if (this.currentStep === 'performance' || this.currentStep === 'overDue' || this.currentStep === 'balance' || this.currentStep === 'balPerformance') {
                myColumns.push({
                    title: '操作',
                    key: 'action',
                    align: 'center',
                    width: 80,
                    render: (h, params, index) => {
                        let content = [
                            h('a', {on: {'click': () => {this.handleReviewResume(params.row, index);}}}, '详情'),
                        ];
                        return h('div', content);
                    }
                });
            }
            this.copyOfColumns = myColumns;
        },
        handleSearchResume() {
            if (this.filter.keywords) {
                let myData = JSON.parse(JSON.stringify(this.recommendResumeList));
                this.copyOfRecommendResumeList = myData.filter(item => (item.resumeId + item.name + item.phone).includes(this.filter.keywords));
            } else {
                this.copyOfRecommendResumeList = JSON.parse(JSON.stringify(this.recommendResumeList));
            }
        },
        handleFindResume() {
            this.showResumeListModal = true;
        },
        addResumeList(resumes) {
            if (resumes) {
                this.recommendResumeList = [...this.recommendResumeList, ...resumes];
                this.handleSearchResume();
            }
        },
        handleReviewResume(row, index) {
            this.showViewResumeModal = true;
            this.resumeId = row.resumeId;
        },
        handleDelResume(row, index) {
            this.recommendResumeList.splice(index, 1);
            this.copyOfRecommendResumeList.splice(index, 1);
        },
        handleSearchFirstRound() {
            if (this.filter.keywords) {
                let myData = JSON.parse(JSON.stringify(this.recommendResumeList));
                this.firstRoundResumeList = myData.filter(item => (item.resumeId + item.name + item.phone).includes(this.filter.keywords));
            } else {
                this.firstRoundResumeList = JSON.parse(JSON.stringify(this.recommendResumeList));
            }
        },
        handleSearchSecondRound() {
            if (this.filter.keywords) {
                let myData = JSON.parse(JSON.stringify(this.selectedFirstRoundResumeList));
                this.secondRoundResumeList = myData.filter(item => (item.resumeId + item.name + item.phone).includes(this.filter.keywords));
            } else {
                this.secondRoundResumeList = JSON.parse(JSON.stringify(this.selectedFirstRoundResumeList));
            }
        },
        handleSearchOffer() {
            if (this.filter.keywords) {
                let myData = JSON.parse(JSON.stringify(this.selectedSecondRoundResumeList));
                this.offerResumeList = myData.filter(item => (item.resumeId + item.name + item.phone).includes(this.filter.keywords));
            } else {
                this.offerResumeList = JSON.parse(JSON.stringify(this.selectedSecondRoundResumeList));
            }
        },
        handleSearchEntry() {
            if (this.filter.keywords) {
                let myData = JSON.parse(JSON.stringify(this.selectedOfferResumeList));
                this.entryResumeList = myData.filter(item => (item.resumeId + item.name + item.phone).includes(this.filter.keywords));
            } else {
                this.entryResumeList = JSON.parse(JSON.stringify(this.selectedOfferResumeList));
            }
        },
        handleSearchPayment() {
            if (this.filter.keywords) {
                let myData = JSON.parse(JSON.stringify(this.selectedEntryResumeList));
                this.paymentResumeList = myData.filter(item => (item.resumeId + item.name + item.phone).includes(this.filter.keywords));
            } else {
                this.paymentResumeList = JSON.parse(JSON.stringify(this.selectedEntryResumeList));
            }
        },
        //上传文件大小
        handExceeded(file) {
            this.$Message.error('文件大小不得超过10M');
        },
        //上传成功
        handleSuccess(res, file, fileList, propName) {
            if (res.code === 1) {
                let name = res.data.currentName;
                downloadFile({fileName: name, type: 'private'}).then((res2) => {
                    let myData = window.URL.createObjectURL(new Blob([res2.data]));
                    this[propName].push({imgName: name, imgData: myData});
                    this.$Message.success('上传成功');
                });
            } else {
                this.$Message.error('上传失败');
            }
        },
        handlePreview(imgData) {
            this.imgView = imgData;
            this.showPreview = true;
        },
        handleRemove(propName, index) {
            reqDeleteFiles([{fileName: this[propName][index].imgName, type: 'private'}]).then((res) => {
                if (res.data.data && res.data.data.length > 0 && res.data.data[0].result) {
                    this[propName].splice(index, 1);
                } else {
                    this.$Message.error('删除失败');
                }
            })
        },
        handleSubmit(type) {
            this.loading = true;
            if (this.validateForm(type)) {
                this.loading = false;
                return false;
            }

            this.editForm.positionId = this.myPosition.id;
            this.editForm.recommendList = this.recommendResumeList.map(item => item.resumeId);
            this.editForm.firstRoundList = this.selectedFirstRoundResumeList.map(item => item.resumeId);
            this.editForm.secondRoundList = this.selectedSecondRoundResumeList.map(item => item.resumeId);
            this.editForm.offerList = this.selectedOfferResumeList.map(item => item.resumeId);
            this.editForm.entryList = this.selectedEntryResumeList.map(item => item.resumeId);
            this.editForm.paymentFile = this.paymentFileArray.map(item => item.imgName);
            this.editForm.paymentList = this.selectedPaymentResumeList.map(item => item.resumeId);
            this.editForm.performanceList = [...this.editForm.paymentList];
            this.editForm.overDueFile = this.overDueFileArray.map(item => item.imgName);
            this.editForm.overDueList = [...this.editForm.performanceList];
            this.editForm.balanceFile = this.balanceFileArray.map(item => item.imgName);
            this.editForm.balanceList = [...this.editForm.overDueList];
            this.editForm.barPerformanceList = [...this.editForm.balanceList];
            this.editForm.submitType = type;
            this.editForm.positionTypeName = this.myPosition.typeName;

            reqSubmitDelivery(this.editForm).then((res) => {
                this.loading = false;
                if (res.data.data === 1) {
                    this.$Message.success('提交成功');
                    this.$emit('getPosition');
                    this.closeModal(false);
                } else {
                    this.$Message.error('提交失败');
                }
            }).catch(()=>{
                this.loading = false;
                this.$Message.error('提交失败');
            })
        },
        validateForm(type) {
            this.errorArray = [];
            if (type === 'approval') {
                if (!this.recommendResumeList || this.recommendResumeList.length === 0) {
                    this.errorArray.push('请添加推荐简历');
                }
                if (!this.selectedFirstRoundResumeList || this.selectedFirstRoundResumeList.length === 0) {
                    this.errorArray.push('请添加企业初试简历');
                }
                if (!this.selectedSecondRoundResumeList || this.selectedSecondRoundResumeList.length === 0) {
                    this.errorArray.push('请添加企业复试简历');
                }
                if (!this.selectedOfferResumeList || this.selectedOfferResumeList.length === 0) {
                    this.errorArray.push('请添加已发Offer的简历');
                }
                if (!this.selectedEntryResumeList || this.selectedEntryResumeList.length === 0) {
                    this.errorArray.push('请添加已入职简历');
                }
                if (!this.selectedPaymentResumeList || this.selectedPaymentResumeList.length === 0) {
                    this.errorArray.push('请添加已付首付款简历');
                }
                if (!this.editForm.payment) {
                    this.errorArray.push('请输入首付款金额');
                }
                if (this.editForm.payment ===  '0') {
                    this.errorArray.push('首付款金额不能为0');
                }
                if (!this.paymentFileArray || this.paymentFileArray.length === 0) {
                    this.errorArray.push('请上传付款附件');
                }
                if (!this.editForm.performance) {
                    this.errorArray.push('请输入首付款业绩金额');
                }
                if (this.editForm.performance ===  '0') {
                    this.errorArray.push('首付款业绩金额不能为0');
                }
                if (!this.editForm.completionTime) {
                    this.errorArray.push('请选择首付款业绩完成时间');
                }
            }

            if (type === 'approvalBal') {
                if (!this.overDueFileArray || this.overDueFileArray.length === 0) {
                    this.errorArray.push('请上传过保附件');
                }
                if (!this.editForm.balance) {
                    this.errorArray.push('请输入尾款金额');
                }
                if (this.editForm.balance ===  '0') {
                    this.errorArray.push('尾款金额不能为0');
                }
                if (!this.balanceFileArray || this.balanceFileArray.length === 0) {
                    this.errorArray.push('请上传尾款附件');
                }
                if (!this.editForm.balPerformance) {
                    this.errorArray.push('请输入尾款业绩金额');
                }
                if (this.editForm.balPerformance === '0') {
                    this.errorArray.push('尾款业绩金额不能为0');
                }
                if (!this.editForm.balPerformanceTime) {
                    this.errorArray.push('请选择尾款业绩完成时间');
                }
            }

            let regex = /^(([1-9]{1}\d{0,7})|(0{1}))(\.\d{1,2})?$/;
            if (this.editForm.payment && !regex.test(this.editForm.payment)) {
                this.errorArray.push('请输入正确的首付款金额。最多两位小数，最大金额99999999.99');
            }
            if (this.editForm.performance && !regex.test(this.editForm.performance)) {
                this.errorArray.push('请输入正确的首付款业绩金额。最多两位小数，最大金额99999999.99');
            }
            if (this.editForm.balPerformance && !regex.test(this.editForm.balPerformance)) {
                this.errorArray.push('请输入正确的尾款业绩金额。最多两位小数，最大金额99999999.99');
            }

            return (this.errorArray.length > 0);
        },
        handleStepChange(name) {
            this.currentStep = name;
            this.filter.keywords = null;
            if (this.currentStep === 'recommend') {
                this.copyOfRecommendResumeList = JSON.parse(JSON.stringify(this.recommendResumeList));
            }
            if (this.currentStep === 'firstRound') {
                this.firstRoundResumeList = JSON.parse(JSON.stringify(this.recommendResumeList));
            }
            if (this.currentStep === 'secondRound') {
                this.secondRoundResumeList = JSON.parse(JSON.stringify(this.selectedFirstRoundResumeList));
            }
            if (this.currentStep === 'offer') {
                this.offerResumeList = JSON.parse(JSON.stringify(this.selectedSecondRoundResumeList));
            }
            if (this.currentStep === 'entry') {
                this.entryResumeList = JSON.parse(JSON.stringify(this.selectedOfferResumeList));
            }
            if (this.currentStep === 'payment') {
                this.paymentResumeList = JSON.parse(JSON.stringify(this.selectedEntryResumeList));
            }
            if (this.currentStep === 'performance') {
                this.performanceResumeList = JSON.parse(JSON.stringify(this.selectedPaymentResumeList));
            }

            this.setColumns();
        },
        closeModal(val) {
            this.$emit('input', val);
        }
    },
    watch: {
        value(val) {
            this.showForm = val;
        },
        showForm(val) {
            if (val) {
                this.myPosition = this.position;
                this.amount = this.myPosition.amount.toFixed(2);
                this.modalTitle = '职位交付 (ID:' + this.position.custId + ')';
                this.init();
            } else {// 反之则关闭页面
                this.paymentFileArray = [];
                this.overDueFileArray = [];
                this.balanceFileArray = [];
                this.showPreview = false;
                this.imgView = null;
                this.isPayReadOnly = false;
                this.errorArray = [];
                this.resumes = [];
                this.recommendResumeList = [];
                this.copyOfRecommendResumeList = [];
                this.firstRoundResumeList = [];
                this.selectedFirstRoundResumeList = [];
                this.secondRoundResumeList = [];
                this.selectedSecondRoundResumeList = [];
                this.offerResumeList = [];
                this.selectedOfferResumeList = [];
                this.entryResumeList = [];
                this.selectedEntryResumeList = [];
                this.paymentResumeList = [];
                this.selectedPaymentResumeList = [];
                this.performanceResumeList = [];
                this.balPerformanceResumeList = [];
                this.overDueResumeList = [];
                this.balanceResumeList = [];
                this.copyOfColumns = [];
                this.approvalHistoryArray = [];
                this.balApprovalHistoryArray = [];
                this.closeModal(val);
            }
        }
    }
}
</script>
