<template>
    <Modal v-model="showForm" :title="modalTitle" width="640" :mask-closable="false">
        <Form class="plain-form" ref="editForm" :model="editForm" :rules="editFormRule" label-colon :label-width="100">
            <div class="custom-form">
                <div class="divider">职位信息</div>
                <div class="custom-form-section">
                    <Row :gutter="16">
                        <Col span="12">
                            <FormItem label="客户">{{ myPosition.customerName }}</FormItem>
                        </Col>
                        <Col span="12">
                            <FormItem label="职位">{{ myPosition.name }}</FormItem>
                        </Col>
                    </Row>
                    <Row :gutter="16">
                        <Col span="12">
                            <FormItem label="类型">{{ myPosition.typeName }}</FormItem>
                        </Col>
                        <Col span="12">
                            <FormItem label="级别">{{ myPosition.levelName }}</FormItem>
                        </Col>
                    </Row>
                    <Row :gutter="16">
                        <Col span="12">
                            <FormItem label="金额">{{ amount }}</FormItem>
                        </Col>
                        <Col span="12">
                            <FormItem label="顾问">{{ myPosition.consultantNames }}</FormItem>
                        </Col>
                    </Row>
                    <Row :gutter="16">
                        <Col span="12">
                            <FormItem label="联系人">{{ myPosition.contact }}</FormItem>
                        </Col>
                        <Col span="12">
                            <FormItem label="联系电话">{{ myPosition.contactPhone }}</FormItem>
                        </Col>
                    </Row>
                    <Row :gutter="16">
                        <Col span="12">
                            <FormItem label="联系人职务">{{ myPosition.contactTitle }}</FormItem>
                        </Col>
                        <Col span="12">
                            <FormItem label="创建人">{{ myPosition.createByName }}</FormItem>
                        </Col>
                    </Row>
                    <Row :gutter="16">
                        <Col span="24">
                            <FormItem label="创建时间">{{ myPosition.createTime }}</FormItem>
                        </Col>
                    </Row>
                    <Row :gutter="16">
                        <Col span="24">
                            <FormItem label="职位说明"><div v-html="myPosition.description"></div></FormItem>
                        </Col>
                    </Row>
                    <Row :gutter="16">
                        <Col span="24">
                            <FormItem label="访谈要点"><div v-html="myPosition.brief"></div></FormItem>
                        </Col>
                    </Row>
                </div>

                <div class="divider">职位变更</div>
                <div class="custom-form-section">
                    <Row :gutter="16">
                        <Col span="24">
                            <FormItem class="plain-item-margin" label="变更用户" prop="consultant">
                                <Select v-model="editForm.consultant" placeholder="请选择" filterable clearable>
                                    <Option v-for="item in consultantArray" :value="item.id" :key="item.id">{{ item.name }}</Option>
                                </Select>
                            </FormItem>
                        </Col>
                    </Row>
                </div>
            </div>
        </Form>
        <div slot="footer">
            <Button type="default" @click="closeModal(false)">取消</Button>
            <Button type="primary" :loading="loading" @click="handleSubmit">确定</Button>
        </div>
    </Modal>
</template>
<script>
import {reqChangePosition, reqGetAvailConsultants} from "@/api/admin-api";

export default {
    name: "markPosition",
    props: {
        value: {
            type: Boolean,
            default: false
        },
        position: {}
    },
    data() {
        return {
            amount: null,
            loading: false,
            consultantArray: [],
            showForm: this.value,
            modalTitle: null,
            myPosition: {},
            editForm: {
                id: null,
                custId: null,
                name: null,
                consultant: null,
            },
            editFormRule:{
                consultant: [{required: true, message: '请选择变更用户', trigger: 'change'}]
            }
        }
    },
    methods: {
        handleSubmit() {
            this.loading = true;
            this.$refs['editForm'].validate((valid) => {
                if (!valid) {
                    this.loading = false;
                    return false;
                }

                reqChangePosition(this.editForm).then((res) => {
                    this.loading = false;
                    if (res.data.code === 1) {
                        this.$Message.success('变更成功');
                        this.$emit('getPosition');
                        this.closeModal(false);
                    } else {
                        this.$Message.error('变更失败');
                    }
                }).catch(()=>{
                    this.loading = false;
                    this.$Message.error('变更失败');
                })
            });
        },
        getAvailConsultants() {
            reqGetAvailConsultants({id:this.editForm.id}).then((res) => {
                this.consultantArray = res.data.data || [];
            }).catch(() => {
                this.consultantArray = [];
            })
        },
        closeModal(val) {
            this.$emit('input', val);
        }
    },
    watch: {
        value(val) {
            this.showForm = val;
        },
        showForm(val) {
            if (val) {
                this.myPosition = this.position;
                this.editForm.id = this.position.id;
                this.editForm.custId = this.position.custId;
                this.editForm.name = this.position.name;
                this.editForm.typeName = this.position.typeName;
                this.amount = this.myPosition.amount.toFixed(2);
                this.modalTitle = '职位变更 (ID:' + this.position.custId + ')';
                this.getAvailConsultants();
            } else {// 反之则关闭页面
                this.closeModal(val);
            }
        }
    }
}
</script>
