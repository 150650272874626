<template>
    <Modal v-model="showForm" :title="modalTitle" width="960" :mask-closable="false">
        <Form class="plain-form" ref="editForm" :model="editForm" :rules="editFormRule" label-colon :label-width="90">
            <div class="custom-form">
                <Alert type="warning" show-icon v-for="(error, index) in errorArray" :key="index">{{error}}</Alert>

                <div class="divider">职位信息</div>
                <div class="custom-form-section">
                    <Row :gutter="16">
                        <Col span="12">
                            <FormItem label="客户">{{ myPosition.customerName }}</FormItem>
                        </Col>
                        <Col span="12">
                            <FormItem label="职位">{{ myPosition.name }}</FormItem>
                        </Col>
                    </Row>
                    <Row :gutter="16">
                        <Col span="12">
                            <FormItem label="类型">{{ myPosition.typeName }}</FormItem>
                        </Col>
                        <Col span="12">
                            <FormItem label="级别">{{ myPosition.levelName }}</FormItem>
                        </Col>
                    </Row>
                    <Row :gutter="16">
                        <Col span="12">
                            <FormItem label="金额">{{ amount }}</FormItem>
                        </Col>
                        <Col span="12">
                            <FormItem label="顾问">{{ myPosition.consultantNames }}</FormItem>
                        </Col>
                    </Row>
                    <Row :gutter="16">
                        <Col span="12">
                            <FormItem label="联系人">{{ myPosition.contact }}</FormItem>
                        </Col>
                        <Col span="12">
                            <FormItem label="联系电话">{{ myPosition.contactPhone }}</FormItem>
                        </Col>
                    </Row>
                    <Row :gutter="16">
                        <Col span="12">
                            <FormItem label="联系人职务">{{ myPosition.contactTitle }}</FormItem>
                        </Col>
                        <Col span="12">
                            <FormItem label="创建人">{{ myPosition.createByName }}</FormItem>
                        </Col>
                    </Row>
                    <Row :gutter="16">
                        <Col span="24">
                            <FormItem label="创建时间">{{ myPosition.createTime }}</FormItem>
                        </Col>
                    </Row>
                    <Row :gutter="16">
                        <Col span="24">
                            <FormItem label="职位说明"><div v-html="myPosition.description"></div></FormItem>
                        </Col>
                    </Row>
                    <Row :gutter="16">
                        <Col span="24">
                            <FormItem label="访谈要点"><div v-html="myPosition.brief"></div></FormItem>
                        </Col>
                    </Row>
                </div>

                <div class="divider">交付进度<span class="tips">注：录入业绩提审后，之前步骤不可编辑</span></div>
                <div class="custom-form-section">
                    <Tabs type="card" v-model="currentStep" size="small" @on-click="handleStepChange" :animated="false">
                        <TabPane label="1. 推荐简历" name="recommend">
                            <div class="filter">
                                <Form ref="filter" label-colon inline>
                                    <FormItem>
                                        <Input v-model="filter.keywords" placeholder="请输入ID，姓名，手机号" clearable/>
                                    </FormItem>
                                    <FormItem>
                                        <Button type="primary" @click="handleSearchResume">搜索</Button>
                                        <Button type="primary" @click="handleFindResume" v-if="!isReadOnly">新增</Button>
                                    </FormItem>
                                </Form>
                            </div>
                            <Table stripe :columns="copyOfColumns" :data="copyOfRecommendResumeList">
                                <template slot-scope="{ row, index }" slot="action">
                                    <a @click="handleReviewResume(row, index)">详情</a>
                                    <Divider type="vertical"/>
                                    <a @click="handleDelResume(row, index)" v-if="!isReadOnly">删除</a>
                                </template>
                            </Table>
                            <div class="tabs-form">
                                <FormItem label="备注">
                                    <Input type="textarea" v-model="editForm.recommendMemo" :maxlength="500" :autosize="{minRows: 2,maxRows: 5}" show-word-limit :disabled="isReadOnly" />
                                </FormItem>
                            </div>
                        </TabPane>
                        <TabPane label="2. 约面" name="booking">
                            <div class="filter">
                                <Form ref="filter" label-colon inline>
                                    <FormItem>
                                        <Input v-model="filter.keywords" placeholder="请输入ID，姓名，手机号" clearable/>
                                    </FormItem>
                                    <FormItem>
                                        <Button type="primary" @click="handleSearchBooking">搜索</Button>
                                    </FormItem>
                                </Form>
                            </div>
                            <Table stripe :columns="copyOfColumns" :data="bookingResumeList"></Table>
                            <div class="tabs-form">
                                <FormItem label="备注">
                                    <Input type="textarea" v-model="editForm.bookingMemo" :maxlength="500" :autosize="{minRows: 2,maxRows: 5}" show-word-limit :disabled="isReadOnly" />
                                </FormItem>
                            </div>
                        </TabPane>
                        <TabPane label="3. 到场" name="present">
                            <div class="filter">
                                <Form ref="filter" label-colon inline>
                                    <FormItem>
                                        <Input v-model="filter.keywords" placeholder="请输入ID，姓名，手机号" clearable/>
                                    </FormItem>
                                    <FormItem>
                                        <Button type="primary" @click="handleSearchPresent">搜索</Button>
                                    </FormItem>
                                </Form>
                            </div>
                            <Table stripe :columns="copyOfColumns" :data="presentResumeList"></Table>
                            <div class="tabs-form">
                                <FormItem label="备注">
                                    <Input type="textarea" v-model="editForm.presentMemo" :maxlength="500" :autosize="{minRows: 2,maxRows: 5}" show-word-limit :disabled="isReadOnly" />
                                </FormItem>
                            </div>
                        </TabPane>
                        <TabPane label="4. 回款" name="receive">
                            <div class="filter">
                                <Form ref="filter" label-colon inline>
                                    <FormItem>
                                        <Input v-model="filter.keywords" placeholder="请输入ID，姓名，手机号" clearable/>
                                    </FormItem>
                                    <FormItem>
                                        <Button type="primary" @click="handleSearchReceive">搜索</Button>
                                    </FormItem>
                                </Form>
                            </div>
                            <Table stripe :columns="copyOfColumns" :data="receiveResumeList"></Table>
                            <div class="tabs-form">
                                <FormItem label="回款金额" required>
                                    <Input v-model="editForm.receive" placeholder="请输入" :disabled="isReadOnly"></Input>
                                </FormItem>
                                <FormItem label="备注">
                                    <Input type="textarea" v-model="editForm.receiveMemo" :maxlength="500" :autosize="{minRows: 2,maxRows: 5}" show-word-limit :disabled="isReadOnly" />
                                </FormItem>
                                <FormItem label="证据截图">
                                    <Upload multiple type="drag"
                                            :headers="header"
                                            :max-size="10 * 1024"
                                            :action="uploadFiles"
                                            :data="{type: 'private'}"
                                            :show-upload-list="false"
                                            :format="['jpg', 'png', 'JPG', 'JPEG']"
                                            accept=".jpg,.png,.JPG,.JPEG"
                                            :on-exceeded-size="handExceeded"
                                            :on-success="(res, file, fileList) => handleSuccess(res, file, fileList, 'receiveFileArray')"
                                            v-if="!isReadOnly">
                                        <div class="upload-btn-l">
                                            <Icon type="md-cloud-upload"></Icon>
                                            <p class="tips">注意：支持.jpg, .jpeg, .png格式, 10M以内</p>
                                        </div>
                                    </Upload>
                                    <div class="upload-image-list" v-for="(item, index) in receiveFileArray">
                                        <img :src="item.imgData" style=""/>
                                        <div class="upload-image-list-cover">
                                            <Icon type="ios-eye" @click.native="handlePreview(item.imgData)"></Icon>
                                            <Icon type="ios-trash" @click.native="handleRemove('receiveFileArray', index)" v-if="!isReadOnly"></Icon>
                                        </div>
                                    </div>
                                </FormItem>
                            </div>
                        </TabPane>
                        <TabPane label="5. 录入业绩" name="performance">
                            <Table stripe :columns="copyOfColumns" :data="performanceResumeList"></Table>
                            <div class="tabs-form">
                                <FormItem label="业绩金额" required>
                                    <Input v-model="editForm.performance" placeholder="请输入" :disabled="isReadOnly"></Input>
                                </FormItem>
                                <FormItem label="完成时间" required>
                                    <DatePicker type="date" placeholder="请选择" v-model="editForm.completionTime" :editable="false" :disabled="isReadOnly"></DatePicker>
                                </FormItem>
                                <FormItem label="备注">
                                    <Input type="textarea" v-model="editForm.performanceMemo" :maxlength="500" :autosize="{minRows: 2,maxRows: 5}" show-word-limit :disabled="isReadOnly" />
                                </FormItem>
                                <FormItem class="plain-item" label="审批进度">
                                    <Timeline>
                                        <TimelineItem v-for="(item, index) in approvalHistoryArray" :value="index" :key="index">
                                            <div class="status">{{ item.status }}<span v-if="item.comment !== null">{{ item.comment }}</span></div>
                                            <div class="operator">{{ item.operatorName }} {{ item.updatedTime }}</div>
                                        </TimelineItem>
                                    </Timeline>
                                </FormItem>
                            </div>
                        </TabPane>
                    </Tabs>
                </div>
            </div>
        </Form>
        <Modal title="查看图片" v-model="showPreview" footer-hide :width="640">
            <img :src="imgView" v-if="showPreview" style="width: 100%"/>
        </Modal>
        <div slot="footer">
            <Button type="default" @click="closeModal(false)">取消</Button>
            <Button type="primary" :loading="loading" @click="handleSubmit('save')" v-if="!isReadOnly && currentStep !== 'performance'">提交</Button>
            <Button type="primary" :loading="loading" @click="handleSubmit('approval')" v-if="!isReadOnly && currentStep === 'performance'">提审</Button>
        </div>
        <view-resume v-model="showViewResumeModal" :id="resumeId"></view-resume>
        <resume-list v-model="showResumeListModal" :positionId="myPosition.id" :resumeList="recommendResumeList" v-on:addResumeList="addResumeList"></resume-list>
    </Modal>
</template>
<script>
import {
    reqGetOpenDelivery,
    reqSubmitDelivery,
    reqGetResumesInDelivery, reqGetApprovalHistoryByDeliveryId
} from "../../../api/admin-api";
import resumeList from './resumeList';
import viewResume from '../../resume/view';
import {downloadFile, reqDeleteFiles, uploadFileURL} from "../../../api/system-api";

export default {
    components: {viewResume, resumeList},
    props: {
        value: {
            type: Boolean,
            default: false
        },
        position: {}
    },
    data() {
        return {
            amount: null,
            showForm: this.value,
            showResumeListModal: false,
            showViewResumeModal: false,
            modalTitle: null,
            loading: false,
            myPosition: {typeName: ''},
            resumeId: null,
            currentStep: 'recommend',
            header: null,
            uploadFiles: uploadFileURL,
            receiveFileArray: [],
            showPreview: false,
            imgView: null,
            isReadOnly: false,
            errorArray: [],
            filter: {
                keywords: null,
            },
            editForm: {
                id: null,
                positionId: null,
                recommendMemo: null,
                bookingMemo: null,
                presentMemo: null,
                receive: null,
                receiveMemo: null,
                performance: null,
                performanceMemo: null,
                completionTime: null,
            },
            editFormRule:{},
            resumes: [],
            recommendResumeList: [],
            copyOfRecommendResumeList: [],
            bookingResumeList: [],
            selectedBookingResumeList: [],
            presentResumeList: [],
            selectedPresentResumeList: [],
            receiveResumeList: [],
            selectedReceiveResumeList: [],
            performanceResumeList: [],
            copyOfColumns: [],
            approvalHistoryArray: [],
            columns: [
                {
                    type: 'index',
                    width: 80,
                    align: 'center',
                    title: '序号'
                },
                {
                    title: 'ID',
                    key: 'resumeId',
                    width: 80,
                },
                {
                    title: '姓名',
                    key: 'name',
                    width: 100,
                },
                {
                    title: '性别',
                    key: 'gender',
                    width: 80,
                },
                {
                    title: '年龄',
                    key: 'age',
                    width: 80,
                },
                {
                    title: '手机号码',
                    key: 'phone',
                    minWidth: 130,
                },
                {
                    title: '最高学历',
                    key: 'highestDegreeName',
                    minWidth: 120,
                }
            ],
        }
    },
    methods: {
        init() {
            let token = sessionStorage.getItem('Authorization');
            this.header = {Authorization: token};
            this.receiveFileArray = [];
            this.showPreview = false;
            this.isReadOnly = false;
            this.imgView = null;

            let promiseResume = new Promise((resolve, reject) => {
                reqGetResumesInDelivery({positionId: this.myPosition.id}).then((res) => {
                    this.resumes = res.data.data || [];

                    this.recommendResumeList = JSON.parse(JSON.stringify(this.resumes)).filter(item => item.type === 'recommend');
                    this.copyOfRecommendResumeList = JSON.parse(JSON.stringify(this.recommendResumeList));

                    this.bookingResumeList = JSON.parse(JSON.stringify(this.recommendResumeList));
                    this.selectedBookingResumeList = JSON.parse(JSON.stringify(this.resumes)).filter(item => item.type === 'booking');

                    this.presentResumeList = JSON.parse(JSON.stringify(this.selectedBookingResumeList));
                    this.selectedPresentResumeList = JSON.parse(JSON.stringify(this.resumes)).filter(item => item.type === 'present');

                    this.receiveResumeList = JSON.parse(JSON.stringify(this.selectedPresentResumeList));
                    this.selectedReceiveResumeList = JSON.parse(JSON.stringify(this.resumes)).filter(item => item.type === 'receive');

                    this.performanceResumeList = JSON.parse(JSON.stringify(this.resumes)).filter(item => item.type === 'performance');

                    resolve(true);
                }).catch(() => {
                    this.recommendResumeList = [];
                    this.copyOfRecommendResumeList = [];
                    this.bookingResumeList = [];
                    this.selectedBookingResumeList = [];
                    this.presentResumeList = [];
                    this.selectedPresentResumeList = [];
                    this.receiveResumeList = [];
                    this.selectedReceiveResumeList = [];
                    this.performanceResumeList = [];
                })
            });
            let promiseSummary = new Promise((resolve, reject) => {
                reqGetOpenDelivery({id: this.myPosition.id}).then((res) => {
                    this.editForm = res.data.data || {};
                    // 是否只读？
                    this.isReadOnly = (this.editForm.approvalStatus > 1);

                    this.editForm.receiveFile && JSON.parse(this.editForm.receiveFile).forEach(name => {
                        downloadFile({fileName: name, type: 'private'}).then((res2) => {
                            let myData = window.URL.createObjectURL(new Blob([res2.data]));
                            this.receiveFileArray.push({imgName: name, imgData: myData});
                        });
                    });

                    resolve(true);
                }).catch((err) => {
                    console.log('err======', err)
                    this.editForm = {};
                })
            });

            Promise.all([promiseSummary, promiseResume]).then(value => {
                reqGetApprovalHistoryByDeliveryId({deliveryId: this.editForm.id}).then((res) => {
                    this.approvalHistoryArray = res.data.data;
                }).catch((err) => {
                    this.approvalHistoryArray = [];
                })

                this.currentStep = ((!this.editForm.approvalStatus || this.editForm.approvalStatus === 0) ? 'recommend' : 'performance');
                this.handleStepChange(this.currentStep);
            }).catch(reason => {
                console.log(reason);
            })
        },
        setColumns() {
            let myColumns = JSON.parse(JSON.stringify(this.columns));
            if (this.currentStep === 'recommend') {
                myColumns.push({
                    title: '操作',
                    key: 'action',
                    align: 'center',
                    width: 120,
                    render: (h, params, index) => {
                        let content = [h('a', {on: {'click': () => {this.handleReviewResume(params.row, index);}}}, '详情')];
                        if (!this.isReadOnly) {
                            content.push(h('Divider', {props: {type: 'vertical'}}));
                            content.push(h('a', {on: {'click': () => {this.handleDelResume(params.row, index);}}}, '删除'));
                        }
                        return h('div', content);
                    }
                });
            }
            if (this.currentStep === 'booking') {
                myColumns.push({
                    title: '操作',
                    key: 'action',
                    align: 'center',
                    width: 80,
                    render: (h, params, index) => {
                        let content = [h('a', {on: {'click': () => {this.handleReviewResume(params.row, index);}}}, '详情')];
                        return h('div', content);
                    }
                },{
                    title: '是否约面？',
                    key: 'action2',
                    align: 'center',
                    width: 120,
                    render: (h, params) => {
                        return h('i-switch', {
                            props: {value: this.selectedBookingResumeList.findIndex(item => item.resumeId === params.row.resumeId) >= 0, disabled: this.isReadOnly},
                            on: {
                                'on-change': (val) => {
                                    if (val) {
                                        this.selectedBookingResumeList.push(params.row);
                                    } else {
                                        let idx = this.selectedBookingResumeList.findIndex(item => item.resumeId === params.row.resumeId);
                                        if (idx >= 0) {
                                            this.selectedBookingResumeList.splice(idx,1);
                                        }
                                    }
                                }
                            }
                        }, [
                            h('span', { slot: 'open' }, '是'),
                            h('span', { slot: 'close' }, '否'),
                        ]);
                    }
                });
            }
            if (this.currentStep === 'present') {
                myColumns.push({
                    title: '操作',
                    key: 'action',
                    align: 'center',
                    width: 80,
                    render: (h, params, index) => {
                        let content = [h('a', {on: {'click': () => {this.handleReviewResume(params.row, index);}}}, '详情')];
                        return h('div', content);
                    }
                },{
                    title: '是否到场？',
                    key: 'action2',
                    align: 'center',
                    width: 120,
                    render: (h, params) => {
                        return h('i-switch', {
                            props: {value: this.selectedPresentResumeList.findIndex(item => item.resumeId === params.row.resumeId) >= 0, disabled: this.isReadOnly},
                            on: {
                                'on-change': (val) => {
                                    if (val) {
                                        this.selectedPresentResumeList.push(params.row);
                                    } else {
                                        let idx = this.selectedPresentResumeList.findIndex(item => item.resumeId === params.row.resumeId);
                                        if (idx >= 0) {
                                            this.selectedPresentResumeList.splice(idx,1);
                                        }
                                    }
                                }
                            }
                        }, [
                            h('span', { slot: 'open' }, '是'),
                            h('span', { slot: 'close' }, '否'),
                        ]);
                    }
                });
            }
            if (this.currentStep === 'receive') {
                myColumns.push({
                    title: '操作',
                    key: 'action',
                    align: 'center',
                    width: 80,
                    render: (h, params, index) => {
                        let content = [h('a', {on: {'click': () => {this.handleReviewResume(params.row, index);}}}, '详情')];
                        return h('div', content);
                    }
                },{
                    title: '是否回款？',
                    key: 'action2',
                    align: 'center',
                    width: 120,
                    render: (h, params) => {
                        return h('i-switch', {
                            props: {value: this.selectedReceiveResumeList.findIndex(item => item.resumeId === params.row.resumeId) >= 0, disabled: this.isReadOnly},
                            on: {
                                'on-change': (val) => {
                                    if (val) {
                                        this.selectedReceiveResumeList.push(params.row);
                                    } else {
                                        let idx = this.selectedReceiveResumeList.findIndex(item => item.resumeId === params.row.resumeId);
                                        if (idx >= 0) {
                                            this.selectedReceiveResumeList.splice(idx,1);
                                        }
                                    }
                                }
                            }
                        }, [
                            h('span', { slot: 'open' }, '是'),
                            h('span', { slot: 'close' }, '否'),
                        ]);
                    }
                });
            }
            if (this.currentStep === 'performance') {
                myColumns.push({
                    title: '操作',
                    key: 'action',
                    align: 'center',
                    width: 80,
                    render: (h, params, index) => {
                        let content = [
                            h('a', {on: {'click': () => {this.handleReviewResume(params.row, index);}}}, '详情'),
                        ];
                        return h('div', content);
                    }
                });
            }
            this.copyOfColumns = myColumns;
        },
        handleSearchResume() {
            if (this.filter.keywords) {
                let myData = JSON.parse(JSON.stringify(this.recommendResumeList));
                this.copyOfRecommendResumeList = myData.filter(item => (item.resumeId + item.name + item.phone).includes(this.filter.keywords));
            } else {
                this.copyOfRecommendResumeList = JSON.parse(JSON.stringify(this.recommendResumeList));
            }
        },
        handleFindResume() {
            this.showResumeListModal = true;
        },
        addResumeList(resumes) {
            if (resumes) {
                this.recommendResumeList = [...this.recommendResumeList, ...resumes];
                this.handleSearchResume();
            }
        },
        handleReviewResume(row, index) {
            this.showViewResumeModal = true;
            this.resumeId = row.resumeId;
        },
        handleDelResume(row, index) {
            this.recommendResumeList.splice(index, 1);
            this.copyOfRecommendResumeList.splice(index, 1);
        },
        handleSearchBooking() {
            if (this.filter.keywords) {
                let myData = JSON.parse(JSON.stringify(this.recommendResumeList));
                this.bookingResumeList = myData.filter(item => (item.resumeId + item.name + item.phone).includes(this.filter.keywords));
            } else {
                this.bookingResumeList = JSON.parse(JSON.stringify(this.recommendResumeList));
            }
        },
        handleSearchPresent() {
            if (this.filter.keywords) {
                let myData = JSON.parse(JSON.stringify(this.selectedBookingResumeList));
                this.presentResumeList = myData.filter(item => (item.resumeId + item.name + item.phone).includes(this.filter.keywords));
            } else {
                this.presentResumeList = JSON.parse(JSON.stringify(this.selectedBookingResumeList));
            }
        },
        handleSearchReceive() {
            if (this.filter.keywords) {
                let myData = JSON.parse(JSON.stringify(this.selectedPresentResumeList));
                this.receiveResumeList = myData.filter(item => (item.resumeId + item.name + item.phone).includes(this.filter.keywords));
            } else {
                this.receiveResumeList = JSON.parse(JSON.stringify(this.selectedPresentResumeList));
            }
        },
        //上传文件大小
        handExceeded(file) {
            this.$Message.error('文件大小不得超过10M');
        },
        //上传成功
        handleSuccess(res, file, fileList, propName) {
            if (res.code === 1) {
                let name = res.data.currentName;
                downloadFile({fileName: name, type: 'private'}).then((res2) => {
                    let myData = window.URL.createObjectURL(new Blob([res2.data]));
                    this[propName].push({imgName: name, imgData: myData});
                    this.$Message.success('上传成功');
                });
            } else {
                this.$Message.error('上传失败');
            }
        },
        handlePreview(imgData) {
            this.imgView = imgData;
            this.showPreview = true;
        },
        handleRemove(propName, index) {
            reqDeleteFiles([{fileName: this[propName][index].imgName, type: 'private'}]).then((res) => {
                if (res.data.data && res.data.data.length > 0 && res.data.data[0].result) {
                    this[propName].splice(index, 1);
                } else {
                    this.$Message.error('删除失败');
                }
            })
        },
        handleSubmit(type) {
            this.loading = true;
            let forAll = (type === 'approval');
            if (this.validateForm(forAll)) {
                this.loading = false;
                return false;
            }

            this.editForm.positionId = this.myPosition.id;
            this.editForm.recommendList = this.recommendResumeList.map(item => item.resumeId);
            this.editForm.bookingList = this.selectedBookingResumeList.map(item => item.resumeId);
            this.editForm.presentList = this.selectedPresentResumeList.map(item => item.resumeId);
            this.editForm.receiveFile = this.receiveFileArray.map(item => item.imgName);
            this.editForm.receiveList = this.selectedReceiveResumeList.map(item => item.resumeId);
            this.editForm.performanceList = [...this.editForm.receiveList];
            this.editForm.submitType = type;
            this.editForm.positionTypeName = this.myPosition.typeName;

            reqSubmitDelivery(this.editForm).then((res) => {
                this.loading = false;
                if (res.data.data === 1) {
                    this.$Message.success('提交成功');
                    this.$emit('getPosition');
                    this.closeModal(false);
                } else {
                    this.$Message.error('提交失败');
                }
            }).catch(()=>{
                this.loading = false;
                this.$Message.error('提交失败');
            })
        },
        validateForm(validAll) {
            this.errorArray = [];
            if (validAll) {
                if (!this.recommendResumeList || this.recommendResumeList.length === 0) {
                    this.errorArray.push('请添加推荐简历');
                }
                if (!this.selectedBookingResumeList || this.selectedBookingResumeList.length === 0) {
                    this.errorArray.push('请添加约面简历');
                }
                if (!this.selectedPresentResumeList || this.selectedPresentResumeList.length === 0) {
                    this.errorArray.push('请添加到场简历');
                }
                if (!this.selectedReceiveResumeList || this.selectedReceiveResumeList.length === 0) {
                    this.errorArray.push('请添加回款简历');
                }
                if (!this.editForm.receive) {
                    this.errorArray.push('请输入回款金额');
                }
                if (this.editForm.receive ===  '0') {
                    this.errorArray.push('回款金额不能为0');
                }
                if (!this.editForm.performance) {
                    this.errorArray.push('请输入业绩金额');
                }
                if (this.editForm.performance === '0') {
                    this.errorArray.push('业绩金额不能为0');
                }
                if (!this.editForm.completionTime) {
                    this.errorArray.push('请选择完成时间');
                }
            }

            let regex = /^(([1-9]{1}\d{0,7})|(0{1}))(\.\d{1,2})?$/;
            if (this.editForm.receive && !regex.test(this.editForm.receive)) {
                this.errorArray.push('请输入正确的回款金额。最多两位小数，最大金额99999999.99');
            }
            if (this.editForm.performance && !regex.test(this.editForm.performance)) {
                this.errorArray.push('请输入正确的业绩金额。最多两位小数，最大金额99999999.99');
            }

            return (this.errorArray.length > 0);
        },
        handleStepChange(name) {
            this.currentStep = name;
            this.filter.keywords = null;
            if (this.currentStep === 'recommend') {
                this.copyOfRecommendResumeList = JSON.parse(JSON.stringify(this.recommendResumeList));
            }
            if (this.currentStep === 'booking') {
                this.bookingResumeList = JSON.parse(JSON.stringify(this.recommendResumeList));
            }
            if (this.currentStep === 'present') {
                this.presentResumeList = JSON.parse(JSON.stringify(this.selectedBookingResumeList));
            }
            if (this.currentStep === 'receive') {
                this.receiveResumeList = JSON.parse(JSON.stringify(this.selectedPresentResumeList));
            }
            if (this.currentStep === 'performance') {
                this.performanceResumeList = JSON.parse(JSON.stringify(this.selectedReceiveResumeList));
            }

            this.setColumns();
        },
        closeModal(val) {
            this.$emit('input', val);
        }
    },
    watch: {
        value(val) {
            this.showForm = val;
        },
        showForm(val) {
            if (val) {
                this.myPosition = this.position;
                this.amount = this.myPosition.amount.toFixed(2);
                this.modalTitle = '职位交付 (ID:' + this.position.custId + ')';
                this.init();
            } else {// 反之则关闭页面
                this.receiveFileArray = [];
                this.showPreview = false;
                this.imgView = null;
                this.isReadOnly = false;
                this.errorArray = [];
                this.resumes = [];
                this.recommendResumeList = [];
                this.copyOfRecommendResumeList = [];
                this.bookingResumeList = [];
                this.selectedBookingResumeList = [];
                this.presentResumeList = [];
                this.selectedPresentResumeList = [];
                this.receiveResumeList = [];
                this.selectedReceiveResumeList = [];
                this.performanceResumeList = [];
                this.copyOfColumns = [];
                this.approvalHistoryArray = [];
                this.closeModal(val);
            }
        }
    }
}
</script>
